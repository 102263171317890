import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//imported module for applying css
import styles from "./my-Style.module.css";
import axios from "axios";

//imported for using more than one class to a single tag
import classNames from "classnames";

//imported for Spinner'
import Spinner from "./Spinner";

//imported for showing toast
import { ToastContainer, toast } from "react-toastify";

// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

//List of driving lisence states
var dlStates;

//Set containing selectes Areas from different states
var SelectedAreaSet = new Set();

//Map contining service VS Fields
var service_RequiredFieldsMap = new Map();

//Array Containing Names of Selected Services
var selectedServiceNames = [];

//Variable Contining current Selected State
var CurrentState;

//Map of States and Areas Corresponding to those states
var stateVsAreas = new Map();

//Created Variable for removing White spaces from the states coming from callouts
var currentStatevalue;

//Variable for handling Submit button disable enable state
var submitButtonCheckbox = false;

//States for Drving Liscence
var statesForDrivingLicense;

//Education Fields Array
var educationFieldsArray = [];

//Employment Fields Array
var employmentFieldArray = [];

//Set for storing the employments checked
var employmentCheckedSet = new Set();

//Set for storing the education checked
var educationCheckedSet = new Set();

//Selected Package
var SelectedPackage;
// Employee id

//Number of Education related to selected Package
var numEduSelectedPackage;

//Number of Education related to selected Package
var numEmpSelectedPackage;

// var for Enabling/Disabling the education checkbox
var disableEducation = false;

//For Enabling/Disabling the employement checkbox
var disableEmployment = false;

//List of Country
var countryList = ["United States of America", "Canada", "United Kingdom"];

//List of Country
var phoneNumberFormatList = ["U.S/C.A +1", "U.K. +44"];

//Map containing Country and their corresponding states
const countryStateCodesMap = new Map();

countryStateCodesMap.set("United States of America", [
  "AA",
  "AE",
  "AK",
  "AL",
  "AP",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "FM",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MH",
  "MI",
  "MN",
  "MO",
  "MP",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "PW",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
]);
countryStateCodesMap.set("Canada", [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NS",
  "ON",
  "PE",
  "QC",
  "SK",
  "NT",
  "NU",
  "YT",
]);
countryStateCodesMap.set("United Kingdom", [
  "ABD",
  "AGY",
  "ALD",
  "ANS",
  "ANT",
  "ARL",
  "ARM",
  "AVN",
  "AYR",
  "BAN",
  "BDF",
  "BEW",
  "BKM",
  "BOR",
  "BRE",
  "BRK",
  "BUT",
  "CAE",
  "CAI",
  "CAM",
  "CAR",
  "CAV",
  "CEN",
  "CGN",
  "CHS",
  "CLA",
  "CLK",
  "CLV",
  "CMA",
  "CMN",
  "CON",
  "COR",
  "CUL",
  "CWD",
  "DBY",
  "DEN",
  "DEV",
  "DFD",
  "DDS",
  "DGY",
  "DNB",
  "DON",
  "DOR",
  "DOW",
  "DUB",
  "DUR",
  "ELN",
  "ERY",
  "ESS",
  "FER",
  "FLN",
  "GAL",
  "GLA",
  "GLS",
  "GMP",
  "GNT",
  "GSY",
  "GTM",
  "GWN",
  "HAM",
  "HEF",
  "HLD",
  "HRT",
  "HUM",
  "HUN",
  "HWR",
  "INV",
  "IOW",
  "JSY",
  "KCD",
  "KEN",
  "KER",
  "KID",
  "KIK",
  "KKD",
  "KRS",
  "LAN",
  "LDY",
  "LEI",
  "LET",
  "LEX",
  "LIM",
  "LIN",
  "LKS",
  "LN",
  "LOG",
  "LOU",
  "LTN",
  "MAY",
  "MAE",
  "MER",
  "MGM",
  "MGY",
  "MLN",
  "MOG",
  "MON",
  "MOR",
  "MSY",
  "NAI",
  "NBL",
  "NFK",
  "NRY",
  "NTH",
  "NTT",
  "NYK",
  "OFF",
  "OKI",
  "OXF",
  "PEE",
  "PEM",
  "PER",
  "POW",
  "RAD",
  "RFW",
  "ROC",
  "ROS",
  "ROX",
  "RUT",
  "SAL",
  "SEL",
  "SFK",
  "SGM",
  "SHI",
  "SLI",
  "SOM",
  "SRK",
  "SRY",
  "SSX",
  "STD",
  "STI",
  "STS",
  "SUT",
  "SXE",
  "SXW",
  "SYK",
  "TAY",
  "TIP",
  "TWR",
  "TYR",
  "WAR",
  "WAT",
  "WEM",
  "WES",
  "WEX",
  "WGM",
  "WIC",
  "WIG",
  "WIL",
  "WIS",
  "WLN",
  "WMD",
  "WOR",
  "WRK",
  "WYK",
  "YKS",
]);

function App() {
  //Declared  finalStatus & ErrorResponse variables for storing response from the background check
  var finalStatus;
  var ErrorResponse;
  var educationId;
  var employmentId;
  var errorResponse;
  useEffect(() => {
    // Function to send data to Node.js
    const sendEmpIdToNode = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const value = queryParams.get("empId");
        if (value) {
          setLoading(true); // Start loading spinner

          try {
            const message = `${finalStatus} ${ErrorResponse}`;
            if (finalStatus === "ACCEPTED") {
              toast.success(message, toastOptions);
            }

            // Call other functions in parallel
            await Promise.all([
              educationFieldsData(),
              getEmploymentFields(),
              educationCreationData(),
              employmentCreationData(),
            ]);
          } catch (error) {
            console.error("Error in parallel function calls:", error);
          }

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/empId`,
              { value }
            );
            setLoading(false); // Stop loading spinner

            // Utility function to parse and set maps
            const parseAndSetMap = (data, setter) => {
              const parsedData = JSON.parse(data);
              setter(new Map(parsedData));
            };

            setbackGroundDetails(response.data.backGroundDetails);
            setstatesArray(response.data.statesArray);
            setPackages(response.data.package_array);

            // Handling various response fields
            parseAndSetMap(
              response.data.stringifiedpackageIdDataMap,
              setPackageIdVsDataMap
            );
            parseAndSetMap(
              response.data.stringifiedPackageIdVsFieldsMap,
              setPackageIdFieldsMap
            );
            parseAndSetMap(
              response.data.stringifiedstatesAreaMap,
              setstatesAreaMap
            );

            // Education
            const parsedEduDetails = JSON.parse(
              response.data.stringifiededucation_DetailsMap
            );
            seteducationIdVsEducationDetails(new Map(parsedEduDetails));

            // Set up default education details
            const eduObj = {
              dateAttendedEnd: "",
              dateAttendedStart: "",
              degreeAwarded: "",
              degreeDate: "",
              educationId: "",
              grade: "",
              institutionAttended: "",
              major: "",
              other: "",
              country: "",
              region: "",
              municipality: "",
              postalCode: "",
            };
            const newMapEducationAddon = new Map();
            newMapEducationAddon.set("0", eduObj);
            seteduDetailsForEducationCreation(newMapEducationAddon);

            // Employment
            const parsedEmpDetails = JSON.parse(
              response.data.stringfiedworkHistory_DetailsMap
            );
            setWorkHistoryIdVsWorkHistoryDetails(new Map(parsedEmpDetails));

            // Set up default employment details
            const empObj = {
              startDate: "",
              endDate: "",
              employer: "",
              street1: "",
              street2: "",
              address: {
                country: "",
                region: "",
                municipality: "",
                postalCode: "",
                combined: "",
              },
              countryCode: 0,
              phoneNumber: "",
              phoneNumberFormat: "",
              phoneNumberCountryCallingCode: 0,
              position: "",
              pay: "",
              duties: "",
              supervisor: "",
              reasonForLeaving: "",
              supervisorFirstName: "",
              supervisorLastName: "",
              supervisorTitle: "",
              supervisorEmail: "",
              isFullTime: false,
              isPartTime: false,
              isTemporaryJob: false,
              isOkayToContact: false,
              includeInBackgroundChecks: false,
              bypassAddressValidationService: false,
            };
            const newMapEmploymentAddon = new Map();
            newMapEmploymentAddon.set("0", empObj);
            setDetailsForEmploymentCreation(newMapEmploymentAddon);
          } catch (error) {
            console.error("Error processing response:", error);
          }
        }
      } catch (error) {
        console.error("Error sending data:", error);
      }
    };

    // Call the function
    sendEmpIdToNode();
  }, []);

  const toastOptions = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 4000, // milliseconds
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  // Method for toast
  function toastMethod() {
    try {
      const message = `${finalStatus} ${ErrorResponse}`;
      if (finalStatus === "ACCEPTED") {
        toast.success(message, toastOptions);
      } else {
        toast.error(message, toastOptions);
      }
    } catch (error) {
      console.error("Error showing toast notification:", error);
    }
  }

  // Method for Toast for Education Created Or not
  function toastEducation() {
    try {
      if (educationId) {
        toast.success(
          `Education created successfully with EducationId: ${educationId}`,
          toastOptions
        );
      } else {
        toast.error(`Error creating Education: ${errorResponse}`, toastOptions);
      }
    } catch (error) {
      console.error("Error displaying toast notification:", error);
    }
  }

  // Method for Toast for Employment Created Or not
  function toastEmployment() {
    try {
      if (employmentId) {
        toast.success(
          `Employment created successfully with EmploymentId: ${employmentId}`,
          toastOptions
        );
      } else {
        toast.error(
          `Error creating Employment: ${errorResponse}`,
          toastOptions
        );
      }
    } catch (error) {
      console.error("Error displaying toast notification:", error);
    }
  }

  function updatePropertyValue(obj, key, value) {
    const keyParts = key.split("."); // Split the key into parts (dot notation)

    keyParts.reduce((currentObj, part, index) => {
      // If we're at the last part of the key, set the value
      if (index === keyParts.length - 1) {
        currentObj[part] = value;
      } else {
        // If the part does not exist yet, create it (or make sure it's an object)
        if (!currentObj[part]) {
          currentObj[part] = {}; // Create empty object if it doesn't exist
        }
      }
      return currentObj[part]; // Continue to the next level
    }, obj);
  }

  function getPropertyValue(obj, key) {
    const keyParts = key.split("."); // Split the key into parts (dot notation)

    return keyParts.reduce((currentObj, part) => {
      // If the part doesn't exist, return undefined
      if (currentObj && currentObj.hasOwnProperty(part)) {
        return currentObj[part];
      } else {
        return undefined;
      }
    }, obj);
  }

  //Array for education fields
  var educationCreationFieldsArray = [];

  //Array for employment fields
  var employmentCreationFieldsArray = [];

  //Array for services Fields along with the services names
  var servicesFieldsArray = [];

  //Used for spinner state
  const [loading, setLoading] = useState(false);

  //Created Set for Unique Areas
  const [newSet, SetNewSet] = useState(new Set());

  //Created Array for Displaying FIELDS on UI
  const [relatedFieldsArray, setFieldsArray] = useState(null);

  //Ceated Array for Showing Areas Corresponding to Selected State
  const [AreaArray, setAreaArray] = useState([]);

  //Created Array of Packages For displaying all packages on UI
  const [package_array, setPackages] = useState([]);

  //Created a map of PackageId and NumberOfEduEmp corresponding to it

  const [packageIdVsDataMap, setPackageIdVsDataMap] = useState({});
  //Array for displaying Services on UI
  const [services_array, setService] = useState([]);

  //Map Containing PackageID and Fields Corresponding
  const [packageIdFieldsMap, setPackageIdFieldsMap] = useState(new Map());

  //Object Containg Data from Callouts
  const [backGroundDetails, setbackGroundDetails] = useState(null);

  // Package Selected or not checkbox
  const [isPackageSelected, setIsPackageSelected] = useState(false);
  const [isCheckboxSelected1, setIsCheckboxSelected1] = useState(true);

  //Map Containind State Vs Areas
  const [statesAreaMap, setstatesAreaMap] = useState(new Map());

  //Array containing all the states from callout
  const [statesArray, setstatesArray] = useState(["None"]);

  //Array cointaing Related Fields
  const [relatedFieldsServiceArray, setrelatedFieldsServiceArray] = useState(
    []
  );
  //Map Containing EducationId VS Education Details
  const [educationIdVsEducationDetails, seteducationIdVsEducationDetails] =
    useState(new Map());

  const [eduDetailsForEducationCreation, seteduDetailsForEducationCreation] =
    useState(new Map());

  const [
    WorkHistoryIdVsWorkHistoryDetails,
    setWorkHistoryIdVsWorkHistoryDetails,
  ] = useState(new Map());

  const [detailsForEmploymentCreation, setDetailsForEmploymentCreation] =
    useState(new Map());

  const [selectedDob, setSelectedDob] = useState(null);

  const [educationEntries, setEducationEntries] = useState({});
  const [employmentEntries, setEmploymentEntries] = useState({});

  useEffect(() => {
    educationIdVsEducationDetails?.forEach((x) => {
      const selectedEducationId = x.educationId?.toString();
      const selectedCountry = x.address?.country;
      const selectedState = x.address?.region;

      if (selectedCountry) {
        const key = "address.country";
        handleEducationCountryChange({
          target: {
            value: selectedCountry,
            id: selectedEducationId,
            name: key,
          },
        });
      }
      if (selectedState) {
        const key = "address.region";
        handleEducationStateChange({
          target: { value: selectedState, id: selectedEducationId, name: key },
        });
      }
    });
  }, [educationIdVsEducationDetails]);

  useEffect(() => {
    WorkHistoryIdVsWorkHistoryDetails?.forEach((x) => {
      const selectedEmploymentId = x.workHistoryId?.toString();
      const selectedCountry = x.employerAddress.country;
      const selectedState = x.employerAddress.region;

      if (selectedCountry) {
        const key = "employerAddress.country";
        handleEmploymentCountryChange({
          target: {
            value: selectedCountry,
            id: selectedEmploymentId,
            name: key,
          },
        });
      }
      if (selectedState) {
        const key = "employerAddress.region";
        handleEmploymentStateChange({
          target: { value: selectedState, id: selectedEmploymentId, name: key },
        });
      }
    });
  }, [WorkHistoryIdVsWorkHistoryDetails]);

  function handleWorkHistory(event) {
    try {
      const selectedEmploymentId = event.target.name;
      var selectedEmployeeDetails =
        document.getElementById(selectedEmploymentId);
      if (event.target.checked) {
        employmentCheckedSet.add(event.target.name);
      } else {
        employmentCheckedSet.delete(event.target.name);
      }
      const empList = document.querySelectorAll(".employmentCheckbox");

      if (employmentCheckedSet.size === Number(numEmpSelectedPackage)) {
        empList.forEach((emp) => {
          if (emp.checked !== true) {
            emp.disabled = true;
          }
        });
      } else {
        empList.forEach((emp) => {
          if (emp.checked !== true) {
            emp.disabled = false;
          }
        });
      }
      if (selectedEmployeeDetails.className.indexOf("Styles.w3show") === -1) {
        selectedEmployeeDetails.className += "Styles.w3show";
      } else {
        selectedEmployeeDetails.className =
          selectedEmployeeDetails.className.replace("Styles.w3show", "");
      }
      setEmpFieldData(event.target.name);
    } catch (error) {
      console.error("An error occurred in handleWorkHistory:", error);
    }

    function setEmpFieldData(selectedEmpId) {
      try {
        var currentSelectedEmploymentFields = document.getElementsByClassName(
          selectedEmpId + "fields"
        );

        for (let i = 0; i < currentSelectedEmploymentFields.length; i++) {
          let field = currentSelectedEmploymentFields[i];
          let selectedEmployment =
            WorkHistoryIdVsWorkHistoryDetails.get(selectedEmpId);

          if (field.name === "startDate" || field.name === "endDate") {
            const dateTimeString = getPropertyValue(
              selectedEmployment,
              field.name
            );
            if (dateTimeString !== null) {
              const result = dateTimeString.split("T")[0];
              field.value = result;
            }
          } else {
            if (field.type === "checkbox") {
              const checkboxValue = getPropertyValue(
                selectedEmployment,
                field.name
              );
              field.checked = checkboxValue ? true : false;
            } else {
              if (getPropertyValue(selectedEmployment, field.name))
                field.value = getPropertyValue(selectedEmployment, field.name);
            }
          }
        }
      } catch (error) {
        console.error("Error in setEmpFieldData:", error);
      }
    }
  }

  //onClick event for handling the EducationSelection
  function handleEducation(event) {
    try {
      const selectedEducationId = event.target.name;
      if (event.target.name !== "0") {
        if (event.target.checked) {
          educationCheckedSet.add(selectedEducationId);
        } else {
          educationCheckedSet.delete(selectedEducationId);
        }
      }
      const educationList = document.querySelectorAll(".eduCheckbox");
      if (educationCheckedSet.size === Number(numEduSelectedPackage)) {
        educationList.forEach((edu) => {
          if (edu.checked !== true) {
            edu.disabled = true;
          }
        });
      } else {
        educationList.forEach((edu) => {
          if (edu.checked !== true) {
            edu.disabled = false;
          }
        });
      }

      var selectedEducation = document.getElementById(selectedEducationId);
      if (selectedEducation.className.indexOf("Styles.w3show") === -1) {
        selectedEducation.className += "Styles.w3show";
      } else {
        selectedEducation.className = selectedEducation.className.replace(
          "Styles.w3show",
          ""
        );
      }
      setEduFieldData(event.target.name);
    } catch (error) {
      console.error("An error occurred in handleEducation:", error);
    }
  }

  // Education country change
  function handleEducationCountryChange(event) {
    const selectedCountry = event.target.value;
    const key = event.target.name;
    const selectedEducationId = event.target.id;

    if (!selectedCountry || !key) return;

    const stateCodes = countryStateCodesMap.get(selectedCountry) || [];

    handleEducationChange({
      target: { value: selectedCountry, id: selectedEducationId, name: key },
    });
    handleEducationChange({
      target: { value: "", id: selectedEducationId, name: "address.region" },
    });

    setEducationEntries((prevEntries) => {
      // Make a copy of the previous state to maintain immutability
      const updatedEntries = { ...prevEntries };

      updatedEntries[selectedEducationId] = {
        ...updatedEntries[selectedEducationId],
        selectedCountry,
        stateCodesBasedOnCountry: stateCodes,
        selectedState: "",
      };

      // Return the updated state object
      return updatedEntries;
    });
  }

  function handleEducationStateChange(event) {
    const selectedState = event.target.value;
    const key = event.target.name;
    const selectedEducationId = event.target.id;

    handleEducationChange({
      target: { value: selectedState, id: selectedEducationId, name: key },
    });

    setEducationEntries((prevEntries) => {
      // Make a copy of the previous state to maintain immutability
      const updatedEntries = { ...prevEntries };

      updatedEntries[selectedEducationId] = {
        ...updatedEntries[selectedEducationId],
        selectedState,
      };

      // Return the updated state object
      return updatedEntries;
    });
  }

  // Employment country change
  function handleEmploymentCountryChange(event) {
    const selectedCountry = event.target.value;
    const key = event.target.name;
    const selectedEmploymentId = event.target.id;

    if (!selectedCountry || !key) return;

    const stateCodes = countryStateCodesMap.get(selectedCountry) || [];

    handleWorkChange({
      target: { value: selectedCountry, id: selectedEmploymentId, name: key },
    });
    handleWorkChange({
      target: {
        value: "",
        id: selectedEmploymentId,
        name: "employerAddress.region",
      },
    });

    setEmploymentEntries((prevEntries) => {
      // Make a copy of the previous state to maintain immutability
      const updatedEntries = { ...prevEntries };

      updatedEntries[selectedEmploymentId] = {
        ...updatedEntries[selectedEmploymentId],
        selectedCountry,
        stateCodesBasedOnCountry: stateCodes,
        selectedState: "",
      };

      // Return the updated state object
      return updatedEntries;
    });
  }

  function handleEmploymentStateChange(event) {
    const selectedState = event.target.value;
    const key = event.target.name;
    const selectedEmploymentId = event.target.id;

    handleWorkChange({
      target: { value: selectedState, id: selectedEmploymentId, name: key },
    });

    setEmploymentEntries((prevEntries) => {
      // Make a copy of the previous state to maintain immutability
      const updatedEntries = { ...prevEntries };

      updatedEntries[selectedEmploymentId] = {
        ...updatedEntries[selectedEmploymentId],
        selectedState,
      };

      // Return the updated state object
      return updatedEntries;
    });
  }

  // Method for filling values into educations
  function setEduFieldData(selectedEduId) {
    try {
      const currentSelectedEduFields = document.getElementsByClassName(
        `${selectedEduId}fields`
      );

      for (let i = 0; i < currentSelectedEduFields.length; i++) {
        let field = currentSelectedEduFields[i];
        let selectedEducation =
          educationIdVsEducationDetails.get(selectedEduId);

        if (
          field.name === "dateAttendedStart" ||
          field.name === "dateAttendedEnd"
        ) {
          const dateTimeString = getPropertyValue(
            selectedEducation,
            field.name
          );
          if (dateTimeString !== null) {
            const result = dateTimeString.split("T")[0];
            field.value = result;
          }
        } else {
          if (field.type === "checkbox") {
            const checkboxValue = getPropertyValue(
              selectedEducation,
              field.name
            );
            field.checked = checkboxValue ? true : false;
          } else {
            if (getPropertyValue(selectedEducation, field.name))
              field.value = getPropertyValue(selectedEducation, field.name);
          }
        }
      }
    } catch (error) {
      console.error("An error occurred in setEduFieldData:", error);
    }
  }

  //Event for handling changes of Services
  const handleServiceChange = (event) => {
    try {
      if (backGroundDetails.State) {
        currentStatevalue = backGroundDetails.State.trimEnd();
      } else {
        currentStatevalue = "None";
      }
      var updateArray = [];
      serviceCalloutData();
      if (event.target.value === "Package") {
        setFieldsArray([]);
        submitButtonCheckbox = false;
        statesForDrivingLicense = statesArray;
        dlStates = statesForDrivingLicense.slice(2);
        const servicesList = document.querySelectorAll(".ServicesClass");
        servicesList.forEach((checkbox) => {
          checkbox.checked = false;
          numEduSelectedPackage = 0;
          numEmpSelectedPackage = 0;
        });
        SelectedAreaSet.clear();
        SetNewSet(Array.from(SelectedAreaSet));
        setrelatedFieldsServiceArray(updateArray);
        if (event.target.checked === false) {
          const packageList = document.querySelectorAll(".packagesClass");
          submitButtonCheckbox = false;
          packageList.forEach((checkbox) => {
            checkbox.checked = false;
            checkbox.value = "packageNone";
          });
        }

        setIsCheckboxSelected1(!event.target.checked);
        setIsPackageSelected(event.target.checked);

        if (isPackageSelected === false) {
          document.getElementById("areasForSelection").className =
            styles.areaSelection;
        } else {
          document.getElementById("areasForSelection").className = "";
        }
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          PackageId: "",
          selectedPackageName: "",
          mapStateAreas: "",
          Services: [],
        }));
        selectedServiceNames = [];
      } else {
        setTimeout(() => {
          formDataFill();
        }, 100);
        statesForDrivingLicense = statesArray;
        dlStates = statesForDrivingLicense.slice(2);
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          PackageId: "",
          selectedPackageName: "",
        }));
        if (event.target.checked) {
          selectedServiceNames.push(event.target.id);
        } else if (event.target.checked === false) {
          setrelatedFieldsServiceArray([]);
          const index = selectedServiceNames.indexOf(event.target.id);
          if (index !== -1) {
            selectedServiceNames.splice(index, 1);
          }
        }
        relatedFields();
        //Method for displaying only the unique fields from the serivices selected
        function relatedFields() {
          try {
            setrelatedFieldsServiceArray([]);
            var concatArray = [];
            var arrayUniqueByKey = [];
            selectedServiceNames.forEach((element) => {
              concatArray = concatArray.concat(
                service_RequiredFieldsMap.get(element)
              );
              let key = "PropertyName";
              arrayUniqueByKey = [
                ...new Map(
                  concatArray.map((item) => [item[key], item])
                ).values(),
              ];
              setrelatedFieldsServiceArray(arrayUniqueByKey);
              setFieldsArray(updateArray);
              setbackGroundDetails((prevDetails) => ({
                ...prevDetails,
                Services: selectedServiceNames,
              }));
            });
            if (arrayUniqueByKey.length > 0) {
              submitButtonCheckbox = true;
            } else if (arrayUniqueByKey.length <= 0) {
              submitButtonCheckbox = false;
            }
          } catch (error) {
            console.error("An error occurred in relatedFields:", error);
          }
        }
      }
    } catch (error) {
      console.error("An error occurred in handleServiceChange:", error);
    }
  };

  // Function for service selections
  function handleServiceSelections(event) {
    try {
      submitButtonCheckbox = event.target.checked; // Simplified assignment
    } catch (error) {
      console.error("An error occurred in handleServiceSelections:", error);
    }
  }

  // Function for handling removal of areas
  function handleRemoveArea(event) {
    try {
      const areaToRemove = event.target.innerHTML;
      const StateSelected = areaToRemove.split(" -")[0];
      SelectedAreaSet.delete(areaToRemove);
      SetNewSet(Array.from(SelectedAreaSet));

      if (stateVsAreas.has(StateSelected)) {
        const arrayRelToCurrentState = stateVsAreas.get(StateSelected);
        const index = arrayRelToCurrentState.indexOf(areaToRemove);
        if (index !== -1) {
          arrayRelToCurrentState.splice(index, 1);
          stateVsAreas.set(StateSelected, arrayRelToCurrentState);

          if (backGroundDetails.mapStateAreas !== null) {
            const obj = Object.fromEntries(stateVsAreas);
            setbackGroundDetails((prevDetails) => ({
              ...prevDetails,
              mapStateAreas: JSON.stringify(obj),
            }));
          }
        }
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          mapStateAreas: Array.from(SelectedAreaSet),
        }));
      }
    } catch (error) {
      console.error("An error occurred in handleRemoveArea:", error);
    }
  }

  //Function for handling Selection of Areas
  function handleAreaClick(event) {
    try {
      var SelectedElement = event.target.innerHTML;

      if (stateVsAreas.has(CurrentState)) {
        var ExistingArray = stateVsAreas.get(CurrentState);
        ExistingArray.push(SelectedElement);
      } else {
        var newArrayOfAreas = [];
        newArrayOfAreas.push(SelectedElement);
        stateVsAreas.set(CurrentState, newArrayOfAreas);
      }
      if (backGroundDetails.mapStateAreas !== null) {
        const obj = Object.fromEntries(stateVsAreas);
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          mapStateAreas: JSON.stringify(obj),
        }));
      }

      SelectedAreaSet.add(CurrentState + " - " + SelectedElement);
      SetNewSet(Array.from(SelectedAreaSet));
      setbackGroundDetails((prevDetails) => ({
        ...prevDetails,
        mapStateAreas: Array.from(SelectedAreaSet),
      }));
    } catch (error) {
      console.error("An error occurred in handleAreaClick:", error);
    }
  }

  //Method for Handling change in State
  async function handleStateChange(event) {
    try {
      CurrentState = event.target.value;
      const valuesCorrToselectedState = await statesAreaMap.get(
        event.target.value
      );
      setAreaArray(valuesCorrToselectedState);
    } catch (error) {
      console.error("An error occurred in handleStateChange:", error);
    }
  }

  //Method for Handling package Change
  function handlePackageChange(event) {
    try {
      disableEducation = false;
      disableEmployment = false;
      educationCheckedSet.clear();
      employmentCheckedSet.clear();
      const EmploymentList = document.querySelectorAll(".employmentCheckbox");
      EmploymentList.forEach((emp) => {
        emp.disabled = false;
        if (emp.checked === true) {
          emp.checked = false;
          var selectedEmployeeDetails = document.getElementById(emp.name);
          if (
            selectedEmployeeDetails.className.indexOf("Styles.w3show") === -1
          ) {
            selectedEmployeeDetails.className += "Styles.w3show";
          } else {
            selectedEmployeeDetails.className =
              selectedEmployeeDetails.className.replace("Styles.w3show", "");
          }
        } else {
          employmentCheckedSet.delete(emp);
        }
      });

      const EducationList = document.querySelectorAll(".eduCheckbox");
      EducationList.forEach((edu) => {
        edu.disabled = false;
        if (edu.checked === true) {
          edu.checked = false;
          var selectedEmployeeDetails = document.getElementById(edu.name);
          if (
            selectedEmployeeDetails.className.indexOf("Styles.w3show") === -1
          ) {
            selectedEmployeeDetails.className += "Styles.w3show";
          } else {
            selectedEmployeeDetails.className =
              selectedEmployeeDetails.className.replace("Styles.w3show", "");
          }
        } else {
          educationCheckedSet.delete(edu);
        }
      });

      numEduSelectedPackage = 0;
      numEmpSelectedPackage = 0;
      var event1 = document.getElementById("selectedPackage");
      SelectedPackage = event1.options[event1.selectedIndex].value;
      setbackGroundDetails((prevDetails) => ({
        ...prevDetails,
        selectedPackageName: SelectedPackage,
      }));

      var selectedPackageId = event1.options[event1.selectedIndex].id;
      if (selectedPackageId) {
        submitButtonCheckbox = true;
      } else if (selectedPackageId === "") {
        submitButtonCheckbox = false;
      }
      if (packageIdVsDataMap.get(selectedPackageId)) {
        var numberEduEmpArray = packageIdVsDataMap.get(selectedPackageId);
        numEduSelectedPackage = Number(numberEduEmpArray[0].NumEdu);
        numEmpSelectedPackage = Number(numberEduEmpArray[0].NumEmp);
      }
      if (selectedPackageId !== undefined && selectedPackageId !== "") {
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          PackageId: selectedPackageId,
        }));
      }
      const newValue = packageIdFieldsMap.get(selectedPackageId);
      if (backGroundDetails.State) {
        currentStatevalue = backGroundDetails.State.trimEnd();
      }
      if (newValue) {
        setFieldsArray(newValue);
      } else {
        setFieldsArray([]);
      }
      setTimeout(() => {
        formDataFill();
      }, 100);
    } catch (error) {
      console.error("An error occurred in handlePackageChange:", error);
    }
  }

  // DOB change handler for DatePicker
  const handleDobChange = (date) => {
    setSelectedDob(date);

    if (date) {
      const day = date.getDate()?.toString().padStart(2, "0"); // Ensures 2-digit day
      const month = (date.getMonth() + 1)?.toString().padStart(2, "0"); // getMonth() is 0-indexed
      const year = date.getFullYear()?.toString();

      // Manually trigger handleValueChangeInForm for each field
      handleValueChangeInForm({ target: { id: "DOBDAY", value: day } });
      handleValueChangeInForm({ target: { id: "DOBMONTH", value: month } });
      handleValueChangeInForm({ target: { id: "DOBYEAR", value: year } });
    }
  };

  //function for filling the data into the Dynamic Form coming from callouts
  function formDataFill() {
    try {
      const checkBoxPackageFields =
        document.querySelectorAll(".checkBoxFields");
      if (checkBoxPackageFields) {
        checkBoxPackageFields.forEach((item) => {
          var PropertyName = item.id;
          if (PropertyName) {
            item.value = backGroundDetails[PropertyName];
          }
        });
      }
      const serviceFieldsList = document.querySelectorAll(".serviceinputs");
      const packageFieldsList = document.querySelectorAll(".packageForminputs");
      if (packageFieldsList) {
        packageFieldsList.forEach((element) => {
          var PropertyName = element.id;
          if (PropertyName) {
            if (backGroundDetails[PropertyName] === undefined) {
              element.value = "";
            } else {
              element.value = backGroundDetails[PropertyName];
            }
          }
        });
      } else {
        setFieldsArray([]);
      }
      if (serviceFieldsList) {
        serviceFieldsList.forEach((element) => {
          element.value = element.id;
        });
      } else {
        setFieldsArray([]);
      }

      // Handling DOB (Day, Month, Year)
      const dobDay = backGroundDetails.DOBDAY;
      const dobMonth = backGroundDetails.DOBMONTH;
      const dobYear = backGroundDetails.DOBYEAR;

      // Set selectedDate only if day, month, and year are available and valid
      if (dobDay && dobMonth && dobYear) {
        const dob = new Date(
          Number(dobYear),
          Number(dobMonth) - 1,
          Number(dobDay)
        );

        // Only set state if the date is valid to avoid resetting
        if (!isNaN(dob)) {
          setSelectedDob(dob); // Set selected date
        }
      }
    } catch (error) {
      console.error("An error occurred in formDataFill:", error);
    }
  }

  //handleClick for input with type as checkbox
  function handleCheckbox(event) {
    try {
      if (event.target.checked) {
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          [event.target.id]: "1",
        }));
      } else {
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          [event.target.id]: "0",
        }));
      }
    } catch (error) {
      console.error("An error occurred in handleCheckbox:", error);
    }
  }

  // Function having data of fields of Education
  function educationFieldsData() {
    try {
      if (educationFieldsArray.length === 0) {
        // educationFieldsArray.push({
        //   ApiName: "School Id",
        //   PropertyName: "educationId",
        //   DataType: "text",
        //   value: "",
        //   Required: false,
        // });
        educationFieldsArray.push({
          ApiName: "School Name",
          PropertyName: "institutionAttended",
          DataType: "text",
          value: "",
          Required: true,
        });
        educationFieldsArray.push({
          ApiName: "School Degree",
          PropertyName: "degreeAwarded",
          DataType: "text",
          value: "",
          Required: false,
        });
        educationFieldsArray.push({
          ApiName: "School Start Date",
          PropertyName: "dateAttendedStart",
          DataType: "date",
          value: "",
          Required: false,
        });
        educationFieldsArray.push({
          ApiName: "School End Date",
          PropertyName: "dateAttendedEnd",
          DataType: "date",
          value: "",
          Required: true,
        });
        educationFieldsArray.push({
          ApiName: "School Major",
          PropertyName: "major",
          DataType: "text",
          value: "",
          Required: false,
        });
        educationFieldsArray.push({
          ApiName: "Zip Code",
          PropertyName: "address.postalCode",
          DataType: "text",
          value: "",
          Required: false,
        });
        educationFieldsArray.push({
          ApiName: "Country",
          PropertyName: "address.country",
          DataType: "text",
          value: "",
          Required: false,
        });
        educationFieldsArray.push({
          ApiName: "State",
          PropertyName: "address.region",
          DataType: "text",
          value: "",
          Required: false,
        });
        educationFieldsArray.push({
          ApiName: "Comments",
          PropertyName: "other",
          DataType: "text",
          value: "",
          Required: false,
        });
      }
    } catch (error) {
      console.error("An error occurred in EducationfieldData :", error);
    }
  }

  //Function having data for fields for education creation
  function educationCreationData() {
    educationCreationFieldsArray = [];
    try {
      educationCreationFieldsArray.push({
        ApiName: "Institute Name",
        PropertyName: "institutionAttended",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Include In BackgroundCheck",
        PropertyName: "includeInBackgroundChecks",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Degree Awarded",
        PropertyName: "degreeAwarded",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Enrollment Status",
        PropertyName: "enrollmentStatus",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Other",
        PropertyName: "other",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Grade",
        PropertyName: "grade",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Start Date",
        PropertyName: "dateAttendedStart",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "End Date",
        PropertyName: "dateAttendedEnd",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Degree Date",
        PropertyName: "degreeDate",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Major",
        PropertyName: "major",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Contact Information",
        PropertyName: "contactInformation",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Street 1",
        PropertyName: "street1",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Street 2",
        PropertyName: "street2",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Municipality",
        PropertyName: "municipality",
        DataType: "text",
        value: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "Postal Code",
        PropertyName: "postalCode",
        DataType: "text",
        value: "",
      });
    } catch (error) {
      console.error("An error occurred in  educationCreationData:", error);
    }
  }

  //Function having data of fields of Employment
  function getEmploymentFields() {
    try {
      if (employmentFieldArray.length === 0) {
        employmentFieldArray.push({
          ApiName: "DOT Verify",
          PropertyName: "DOTVerify",
          DataType: "text",
          value: "",
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Temp Agency",
          PropertyName: "TempAgency",
          DataType: "text",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp Comments",
          PropertyName: "CompComments",
          DataType: "text",
          value: "",
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Comp Contact",
          PropertyName: "CompContact",
          DataType: "text",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Phone Format",
          PropertyName: "phoneNumberFormat",
          DataType: "select",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp ContactPhone",
          PropertyName: "phoneNumber",
          DataType: "text",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp Name",
          PropertyName: "employer",
          DataType: "text",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp Address",
          PropertyName: "employerAddress.combined",
          DataType: "text",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp Country",
          PropertyName: "employerAddress.country",
          DataType: "select",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp State",
          PropertyName: "employerAddress.region",
          DataType: "select",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp City",
          PropertyName: "employerAddress.municipality",
          DataType: "text",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp Zip",
          PropertyName: "employerAddress.postalCode",
          DataType: "text",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp Leave",
          PropertyName: "reasonForLeaving",
          DataType: "text",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Hire Date",
          PropertyName: "startDate",
          DataType: "date",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "End Date",
          PropertyName: "endDate",
          DataType: "date",
          value: "",
          Required: true,
        });
        employmentFieldArray.push({
          ApiName: "Comp Title",
          PropertyName: "position",
          DataType: "text",
          value: "",
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Comp Salary",
          PropertyName: "pay",
          DataType: "text",
          value: "",
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Is Full Time",
          PropertyName: "isFullTime",
          DataType: "checkbox",
          value: false,
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Is Part Time",
          PropertyName: "isPartTime",
          DataType: "checkbox",
          value: false,
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Is Temporary Time",
          PropertyName: "isTemporaryJob",
          DataType: "checkbox",
          value: false,
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Is Ok to Connect Time",
          PropertyName: "isOkayToContact",
          DataType: "checkbox",
          value: false,
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Include In Background Checks",
          PropertyName: "includeInBackgroundChecks",
          DataType: "checkbox",
          value: false,
          Required: false,
        });
        employmentFieldArray.push({
          ApiName: "Bypass Address validation service",
          PropertyName: "bypassAddressValidationService",
          DataType: "checkbox",
          value: false,
          Required: false,
        });
      }
    } catch (error) {
      console.error("An error occurred in getEmploymentFields:", error);
    }
  }

  //Function having data for fields for education creation
  function employmentCreationData() {
    employmentCreationFieldsArray = [];
    try {
      employmentCreationFieldsArray.push({
        ApiName: "DOT Verify",
        PropertyName: "DOTVerify",
        DataType: "text",
        value: "",
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Temp Agency",
        PropertyName: "TempAgency",
        DataType: "text",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Comments",
        PropertyName: "CompComments",
        DataType: "text",
        value: "",
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Contact",
        PropertyName: "CompContact",
        DataType: "text",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Phone Format",
        PropertyName: "phoneNumberFormat",
        DataType: "select",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp ContactPhone",
        PropertyName: "phoneNumber",
        DataType: "text",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Name",
        PropertyName: "employer",
        DataType: "text",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Address",
        PropertyName: "employerAddress.combined",
        DataType: "text",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Country",
        PropertyName: "employerAddress.country",
        DataType: "select",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp State",
        PropertyName: "employerAddress.region",
        DataType: "select",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp City",
        PropertyName: "employerAddress.municipality",
        DataType: "text",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Zip",
        PropertyName: "employerAddress.postalCode",
        DataType: "text",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Leave",
        PropertyName: "reasonForLeaving",
        DataType: "text",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Hire Date",
        PropertyName: "startDate",
        DataType: "date",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "End Date",
        PropertyName: "endDate",
        DataType: "date",
        value: "",
        Required: true,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Title",
        PropertyName: "position",
        DataType: "text",
        value: "",
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Comp Salary",
        PropertyName: "pay",
        DataType: "text",
        value: "",
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Is Full Time",
        PropertyName: "isFullTime",
        DataType: "checkbox",
        value: false,
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Is Part Time",
        PropertyName: "isPartTime",
        DataType: "checkbox",
        value: false,
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Is Temporary Time",
        PropertyName: "isTemporaryJob",
        DataType: "checkbox",
        value: false,
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Is Ok to Connect Time",
        PropertyName: "isOkayToContact",
        DataType: "checkbox",
        value: false,
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Include In Background Checks",
        PropertyName: "includeInBackgroundChecks",
        DataType: "checkbox",
        value: false,
        Required: false,
      });
      employmentCreationFieldsArray.push({
        ApiName: "Bypass Address validation service",
        PropertyName: "bypassAddressValidationService",
        DataType: "checkbox",
        value: false,
        Required: false,
      });
    } catch (error) {
      console.error("An error occurred in  employmentCreationData:", error);
    }
  }

  //Function having DAta of fields of Different Services
  function serviceCalloutData() {
    try {
      for (let i = 0; i < services_array.length; i++) {
        servicesFieldsArray = [];
        try {
          if (
            backGroundDetails.YearsSearch !== "" ||
            backGroundDetails.YearsSearch != null ||
            backGroundDetails.YearsSearch !== undefined
          ) {
            setbackGroundDetails((prevDetails) => ({
              ...prevDetails,
              YearsSearch: 7,
            }));
          }
        } catch (error) {
          console.error("Error processing YearsSearch", error);
        }

        try {
          if (services_array[i].pname === "CIA Super Search") {
            servicesFieldsArray.push({
              ApiName: "First Name",
              PropertyName: "FirstName",
              DataType: "text",
              value: backGroundDetails.FirstName,
              Required: true,
            });

            servicesFieldsArray.push({
              ApiName: "Last Name",
              PropertyName: "LastName",
              DataType: "text",
              value: backGroundDetails.LastName,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "Middle Initial",
              PropertyName: "MiddleInitial",
              DataType: "text",
              value: backGroundDetails.MiddleInitial,
              Required: false,
            });
            servicesFieldsArray.push({
              ApiName: "DOB",
              PropertyName: "DOBDAY",
              DataType: "number",
              value: backGroundDetails.DOBDAY,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "SSN ",
              PropertyName: "SSN1",
              DataType: "number",
              value: backGroundDetails.SSN1,
              Required: false,
            });
          } else if (services_array[i].pname === "CIA Super Search (Alias)") {
            servicesFieldsArray.push({
              ApiName: "First Name",
              PropertyName: "FirstName",
              DataType: "text",
              value: backGroundDetails.FirstName,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "Last Name",
              PropertyName: "LastName",
              DataType: "text",
              value: backGroundDetails.LastName,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "Middle Initial",
              PropertyName: "MiddleInitial",
              DataType: "text",
              value: backGroundDetails.MiddleInitial,
              Required: false,
            });
            servicesFieldsArray.push({
              ApiName: "DOB",
              PropertyName: "DOBDAY",
              DataType: "number",
              value: backGroundDetails.DOBDAY,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "SSN ",
              PropertyName: "SSN1",
              DataType: "number",
              value: backGroundDetails.SSN1,
              Required: true,
            });
          } else if (services_array[i].pname === "Criminal Record") {
            servicesFieldsArray.push({
              ApiName: "First Name",
              PropertyName: "FirstName",
              DataType: "text",
              value: backGroundDetails.FirstName,
              Required: true,
            });

            servicesFieldsArray.push({
              ApiName: "Last Name",
              PropertyName: "LastName",
              DataType: "text",
              value: backGroundDetails.LastName,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "Middle Initial",
              PropertyName: "MiddleInitial",
              DataType: "text",
              value: backGroundDetails.MiddleInitial,
              Required: false,
            });
            servicesFieldsArray.push({
              ApiName: "DOB",
              PropertyName: "DOBDAY",
              DataType: "number",
              value: backGroundDetails.DOBDAY,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "SSN ",
              PropertyName: "SSN1",
              DataType: "number",
              value: backGroundDetails.SSN1,
              Required: false,
            });
          } else if (services_array[i].pname === "Motor Vehicle Report") {
            servicesFieldsArray.push({
              ApiName: "First Name",
              PropertyName: "FirstName",
              DataType: "text",
              value: backGroundDetails.FirstName,
              Required: true,
            });

            servicesFieldsArray.push({
              ApiName: "Last Name",
              PropertyName: "LastName",
              DataType: "text",
              value: backGroundDetails.LastName,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "Middle Initial",
              PropertyName: "MiddleInitial",
              DataType: "text",
              value: backGroundDetails.MiddleInitial,
              Required: false,
            });
            servicesFieldsArray.push({
              ApiName: "DOB",
              PropertyName: "DOBDAY",
              DataType: "number",
              value: backGroundDetails.DOBDAY,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "DL Number",
              PropertyName: "DLNumber",
              DataType: "text",
              value: backGroundDetails.DLNumber,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "DL State",
              PropertyName: "DLState",
              DataType: "text",
              value: backGroundDetails.DLState,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "SSN ",
              PropertyName: "SSN1",
              DataType: "number",
              value: backGroundDetails.SSN1,
              Required: false,
            });
          } else if (services_array[i].pname === "National Sexual Offender") {
            servicesFieldsArray.push({
              ApiName: "First Name",
              PropertyName: "FirstName",
              DataType: "text",
              value: backGroundDetails.FirstName,
              Requirned: true,
            });

            servicesFieldsArray.push({
              ApiName: "Last Name",
              PropertyName: "LastName",
              DataType: "text",
              value: backGroundDetails.LastName,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "Middle Initial",
              PropertyName: "MiddleInitial",
              DataType: "text",
              value: backGroundDetails.MiddleInitial,
              Required: false,
            });
            servicesFieldsArray.push({
              ApiName: "DOB",
              PropertyName: "DOBDAY",
              DataType: "number",
              value: backGroundDetails.DOBDAY,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "SSN ",
              PropertyName: "SSN1",
              DataType: "number",
              value: backGroundDetails.SSN1,
              Required: false,
            });
          } else if (services_array[i].pname === "Sexual Offender Registry") {
            Array.from(educationIdVsEducationDetails.entries());
            servicesFieldsArray.push({
              ApiName: "First Name",
              PropertyName: "FirstName",
              DataType: "text",
              value: backGroundDetails.FirstName,
              Required: true,
            });

            servicesFieldsArray.push({
              ApiName: "Last Name",
              PropertyName: "LastName",
              DataType: "text",
              value: backGroundDetails.LastName,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "Middle Initial",
              PropertyName: "MiddleInitial",
              DataType: "text",
              value: backGroundDetails.MiddleInitial,
              Required: false,
            });
            servicesFieldsArray.push({
              ApiName: "DOB",
              PropertyName: "DOBDAY",
              DataType: "number",
              value: backGroundDetails.DOBDAY,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "State",
              PropertyName: "State",
              DataType: "text",
              value: backGroundDetails.State,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "SSN ",
              PropertyName: "SSN1",
              DataType: "number",
              value: backGroundDetails.SSN1,
              Required: false,
            });
          } else if (services_array[i].pname === "Social Security Trace") {
            servicesFieldsArray.push({
              ApiName: "First Name",
              PropertyName: "FirstName",
              DataType: "text",
              value: backGroundDetails.FirstName,
              Required: true,
            });

            servicesFieldsArray.push({
              ApiName: "Last Name",
              PropertyName: "LastName",
              DataType: "text",
              value: backGroundDetails.LastName,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "Middle Initial",
              PropertyName: "MiddleInitial",
              DataType: "text",
              value: backGroundDetails.MiddleInitial,
              Required: false,
            });
            servicesFieldsArray.push({
              ApiName: "DOB",
              PropertyName: "DOBDAY",
              DataType: "number",
              value: backGroundDetails.DOBDAY,
              Required: true,
            });
            servicesFieldsArray.push({
              ApiName: "SSN",
              PropertyName: "SSN1",
              DataType: "number",
              value: backGroundDetails.SSN1,
              Required: true,
            });
          }
          servicesFieldsArray.push({
            ApiName: "Years Search",
            PropertyName: "YearsSearch",
            DataType: "number",
            value: backGroundDetails.YearsSearch,
            Required: true,
          });
          servicesFieldsArray.push({
            ApiName: "Possible Record",
            PropertyName: "PossibleRecord",
            DataType: "checkbox",
            value: backGroundDetails.PossibleRecord,
            Required: false,
          });
          servicesFieldsArray.push({
            ApiName: "Record Details",
            PropertyName: "RecordDetails",
            DataType: "text",
            value: backGroundDetails.RecordDetails,
            Required: false,
          });
          servicesFieldsArray.push({
            ApiName: "Reference Number",
            PropertyName: "RefNumber",
            DataType: "text",
            value: backGroundDetails.RefNumber,
            Required: false,
          });
          servicesFieldsArray.push({
            ApiName: "Rush Order",
            PropertyName: "RushOrder",
            DataType: "checkbox",
            value: backGroundDetails.RushOrder,
            Required: false,
          });
          servicesFieldsArray.push({
            ApiName: "Search Info",
            PropertyName: "SearchInfo",
            DataType: "text",
            value: backGroundDetails.SearchInfo,
            Required: false,
          });
          servicesFieldsArray.push({
            ApiName: "Alias First",
            PropertyName: "AliasFirst",
            DataType: "text",
            value: backGroundDetails.AliasFirst,
            Required: false,
          });
          servicesFieldsArray.push({
            ApiName: "Alias Last",
            PropertyName: "AliasLast",
            DataType: "text",
            value: backGroundDetails.AliasLast,
            Required: false,
          });
        } catch (error) {
          console.error(
            "Error processing fields for service:",
            services_array[i].pname,
            error
          );
        }
        try {
          service_RequiredFieldsMap.set(
            services_array[i].pname,
            servicesFieldsArray
          );
        } catch (error) {
          console.error(
            "Error setting service_RequiredFieldsMap for service:",
            services_array[i].pname,
            error
          );
        }
      }
    } catch (error) {
      console.error("Error in serviceCalloutData", error);
    }
  }

  useEffect(() => {}, [AreaArray]);

  //Method for handling Education Change
  function handleEducationChange(event) {
    try {
      var value = event.target.value;
      var key = event.target.name;
      var selectedEducationId = event.target.id;
      if (event.target.type === "checkbox") {
        value = event.target.checked;
      }
      if (selectedEducationId !== "0") {
        updatePropertyValue(
          educationIdVsEducationDetails.get(selectedEducationId),
          key,
          value
        );
      } else {
        updatePropertyValue(
          eduDetailsForEducationCreation.get(selectedEducationId),
          key,
          value
        );
      }
    } catch (error) {
      console.error("Error updating education details:", error);
    }
  }

  //Method for handling workHistory change
  function handleWorkChange(event) {
    try {
      var value = event.target.value;
      var key = event.target.name;
      var selectedEmploymentId = event.target.id;
      if (event.target.type === "checkbox") {
        value = event.target.checked;
      }
      if (selectedEmploymentId !== "0") {
        updatePropertyValue(
          WorkHistoryIdVsWorkHistoryDetails.get(selectedEmploymentId),
          key,
          value
        );
      } else {
        updatePropertyValue(
          detailsForEmploymentCreation.get(selectedEmploymentId),
          key,
          value
        );
      }
    } catch (error) {
      console.error("Error updating work history details:", error);
    }
  }

  //Method for creation of body for Employment and Education Callout
  function createBody() {
    debugger;
    try {
      var eduServiceBody = "";
      var empServiceBody = "";
      const empCheckedArray = Array.from(employmentCheckedSet);
      if (empCheckedArray.length) {
        try {
          for (let i = 0; i < empCheckedArray.length; i++) {
            empServiceBody =
              empServiceBody +
              "<Search>" +
              "<Type>Employment</Type>" +
              "<EmpDetails>" +
              "<DOTVerify></DOTVerify>" +
              "<CompName>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employer ?? "") +
              "</CompName>" +
              "<TempAgency></TempAgency>" +
              "<CompAddress>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddress?.combined ?? "") +
              "</CompAddress>" +
              "<CompCountry>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddress?.country ?? "") +
              "</CompCountry>" +
              "<CompState>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddress?.region ?? "") +
              "</CompState>" +
              "<CompCity>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddress?.municipality ?? "") +
              "</CompCity>" +
              "<CompZip>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddress?.postalCode ?? "") +
              "</CompZip>" +
              "<CompLeave>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .reasonForLeaving ?? "") +
              "</CompLeave>" +
              "<HireDate>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .startDate ?? "") +
              "</HireDate>" +
              "<EndDate>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .endDate ?? "") +
              "</EndDate>" +
              "<CompTitle>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .position ?? "") +
              "</CompTitle>" +
              "<CompSalary>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i]).pay ??
                "") +
              "</CompSalary>" +
              "<CompContact>John Doe</CompContact>" +
              "<CompContactPhoneNumberFormat>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .phoneNumberFormat ?? "") +
              "</CompContactPhoneNumberFormat>" +
              "<CompContactPhone>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .phoneNumber ?? "") +
              "</CompContactPhone>" +
              "<IsFullTime>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .isFullTime ?? "") +
              "</IsFullTime>" +
              "<IsPartTime>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .isPartTime ?? "") +
              "</IsPartTime>" +
              "<IsTemporaryJob>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .isTemporaryJob ?? "") +
              "</IsTemporaryJob>" +
              "<IsOkayToContact>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .isOkayToContact ?? "") +
              "</IsOkayToContact>" +
              "<IncludeInBackgroundChecks>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .includeInBackgroundChecks ?? "") +
              "</IncludeInBackgroundChecks>" +
              "<BypassAddressValidationService>" +
              (WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .bypassAddressValidationService ?? "") +
              "</BypassAddressValidationService>" +
              "<CompComments>Comments go here</CompComments>" +
              "</EmpDetails>" +
              "<RefNumber>EMP-REF</RefNumber>" +
              "</Search>";
          }
        } catch (error) {
          console.error("Error processing employment data:", error);
        }
      }

      const eduCheckedArray = Array.from(educationCheckedSet);
      if (eduCheckedArray.length) {
        try {
          for (let i = 0; i < eduCheckedArray.length; i++) {
            eduServiceBody =
              eduServiceBody +
              "<Search>" +
              "<Type>Education</Type>" +
              "<EduDetails>" +
              "<SchName>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i])
                .institutionAttended ?? "") +
              "</SchName>" +
              "<SchAddress>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i]).address
                ?.combined ?? "") +
              "</SchAddress>" +
              "<SchCity>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i]).address
                ?.municipality ?? "") +
              "</SchCity>" +
              "<SchCountry>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i]).address
                ?.country ?? "") +
              "</SchCountry>" +
              "<SchRegion>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i]).address
                ?.region ?? "") +
              "</SchRegion>" +
              "<SchZip>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i]).address
                ?.postalCode ?? "") +
              "</SchZip>" +
              "<SchStartDate>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i])
                .dateAttendedStart ?? "") +
              "</SchStartDate>" +
              "<SchEndDate>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i])
                .dateAttendedEnd ?? "") +
              "</SchEndDate>" +
              "<SchMajor>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i]).major ??
                "") +
              "</SchMajor>" +
              "<SchDegree>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i])
                .degreeAwarded ?? "") +
              "</SchDegree>" +
              "<SchID>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i])
                .educationId ?? "") +
              "</SchID>" +
              "<SchComments>" +
              (educationIdVsEducationDetails.get(eduCheckedArray[i]).other ??
                "") +
              "</SchComments>" +
              "</EduDetails>" +
              "<RefNumber>EDU-REF</RefNumber>" +
              "</Search>";
          }
        } catch (error) {
          console.error("Error processing education data:", error);
        }
      }
      if (eduCheckedArray.length !== 0 && empCheckedArray.length === 0) {
        return eduServiceBody;
      } else if (eduCheckedArray.length === 0 && empCheckedArray.length !== 0) {
        return empServiceBody;
      } else if (eduCheckedArray.length !== 0 && empCheckedArray.length !== 0) {
        return eduServiceBody + empServiceBody;
      } else {
        return null;
      }
    } catch (error) {
      console.log(
        "Error processing body for education and Employement selected:",
        error
      );
    }
  }

  //method for handling Education creation
  const handleEduCreation = async (event) => {
    event.preventDefault();
    setLoading(true);
    // const finalBody = Array.from(
    //   eduDetailsForEducationCreation.entries()
    // ).reduce((acc, [key, value]) => {
    //   acc[key] = value;
    //   return acc;
    // }, {});
    const finalBody = Array.from(
      eduDetailsForEducationCreation.entries()
    ).reduce((acc, [key, value]) => {
      acc[key] = value || ""; // Set empty string for empty values
      if (value.hasOwnProperty("address")) {
        acc[key].country = value.address.country || "";
        acc[key].region = value.address.region || "";
        acc[key].postalCode = value.address.postalCode || "";
      }
      return acc;
    }, {});
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/eduCreationDetails`, {
          finalBody,
        })
        .then((response) => response)
        .then((data) => {
          let dataEdu = data.data.response;
          var parsedEduDetails = JSON.parse(
            data.data.stringifiededucation_DetailsMap
          );
          var newMapEduDetails = new Map(parsedEduDetails);
          seteducationIdVsEducationDetails(newMapEduDetails);
          if (typeof dataEdu == "string") {
            educationId = JSON.parse(dataEdu).educationId;
          } else {
            educationId = dataEdu.educationId;
          }
          errorResponse = data.data.Error;
        });
    } catch (event) {
      console.log("Error in sending data for Education in api", event);
    }
    setLoading(false);
    const eduObj = {
      dateAttendedEnd: "",
      dateAttendedStart: "",
      degreeAwarded: "",
      degreeDate: "",
      educationId: "",
      grade: "",
      institutionAttended: "",
      major: "",
      other: "",
      country: "",
      region: "",
      municipality: "",
      postalCode: "",
      countryCode: 0,
    };
    var newMapEducationAddon = new Map();
    newMapEducationAddon.set("0", eduObj);

    setEmploymentEntries((prevState) => ({
      ...prevState,
      0: {},
    }));
    seteduDetailsForEducationCreation(newMapEducationAddon);
    handleModalClose();
    toastEducation();
  };

  //method for handling Employment creation
  const handleEmploymentCreation = async (event) => {
    event.preventDefault();
    setLoading(true);
    const finalBody = Array.from(detailsForEmploymentCreation.entries()).reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        if (value.hasOwnProperty("employerAddress")) {
          acc[key].postalCode = value.employerAddress.postalCode || "";
          acc[key].region = value.employerAddress.region || "";
          acc[key].country = value.employerAddress.country || "";
          acc[key].municipality = value.employerAddress.municipality || "";
        }
        return acc;
      },
      {}
    );
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/employmentCreationDetails`,
          {
            finalBody,
          }
        )
        .then((response) => response)
        .then((data) => {
          let dataEmployment = data.data.response;
          var parsedEmploymentDetails = JSON.parse(
            data.data.stringfiedworkHistory_DetailsMap
          );
          var newMapEmploymentDetails = new Map(parsedEmploymentDetails);
          setWorkHistoryIdVsWorkHistoryDetails(newMapEmploymentDetails);
          if (typeof dataEmployment == "string") {
            employmentId = JSON.parse(dataEmployment).workHistoryId;
          } else {
            employmentId = dataEmployment.workHistoryId;
          }
          errorResponse = data.data.Error;
        });
    } catch (event) {
      console.log("Error in sending data for Employment in api", event);
    }
    setLoading(false);
    const empObj = {
      startDate: "",
      endDate: "",
      employer: "",
      street1: "",
      street2: "",
      address: {
        country: "",
        region: "",
        municipality: "",
        postalCode: "",
        combined: "",
      },
      countryCode: 0,
      phoneNumber: "",
      phoneNumberFormat: "",
      phoneNumberCountryCallingCode: 0,
      position: "",
      pay: "",
      duties: "",
      supervisor: "",
      reasonForLeaving: "",
      supervisorFirstName: "",
      supervisorLastName: "",
      supervisorTitle: "",
      supervisorEmail: "",
      isFullTime: false,
      isPartTime: false,
      isTemporaryJob: false,
      isOkayToContact: false,
      includeInBackgroundChecks: false,
      bypassAddressValidationService: false,
    };

    var newMapEmploymentAddon = new Map();
    newMapEmploymentAddon.set("0", empObj);

    setEmploymentEntries((prevState) => ({
      ...prevState,
      0: {},
    }));
    setDetailsForEmploymentCreation(newMapEmploymentAddon);
    handleEmploymentModalClose();
    toastEmployment();
  };

  // Method for handling submit Button of Form
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    var finalBodyEduEmp = createBody();
    let isInvalidForm = false;

    // Validate state and area selection in case of service selection
    if (
      backGroundDetails.Services?.length &&
      !backGroundDetails.mapStateAreas
    ) {
      toast.error(
        "Please select state and area to proceed further.",
        toastOptions
      );

      setLoading(false);
      return;
    }

    // Extract the 'name' attribute from each checked checkbox
    const checkedEducationCheckboxes = document.querySelectorAll(
      'input[type="checkbox"].eduCheckbox:checked'
    );
    const checkedEducationNames = Array.from(checkedEducationCheckboxes).map(
      (checkbox) => checkbox.name
    );

    for (let i = 0; i < checkedEducationNames.length; i++) {
      const educationName = checkedEducationNames[i];
      const innerForm = document.querySelector(
        `div[data-elem-id="EDU-${educationName}"] form.innerEduForm`
      );
      if (innerForm && !innerForm.reportValidity()) {
        const invalidElement = innerForm.querySelector(":invalid");
        if (invalidElement) {
          event.preventDefault();
          invalidElement.focus();
          isInvalidForm = true;
        }
        setLoading(false);
        return;
      }
    }
    if (isInvalidForm) return;

    // Extract the 'name' attribute from each checked checkbox
    const checkedEmploymentCheckboxes = document.querySelectorAll(
      'input[type="checkbox"].employmentCheckbox:checked'
    );
    const checkedEmploymentNames = Array.from(checkedEmploymentCheckboxes).map(
      (checkbox) => checkbox.name
    );

    for (let i = 0; i < checkedEmploymentNames.length; i++) {
      const employmentName = checkedEmploymentNames[i];
      const innerForm = document.querySelector(
        `div[data-elem-id="EMP-${employmentName}"] form.innerEmpForm`
      );
      if (innerForm && !innerForm.reportValidity()) {
        const invalidElement = innerForm.querySelector(":invalid");
        if (invalidElement) {
          event.preventDefault();
          invalidElement.focus();
          isInvalidForm = true;
        }
        setLoading(false);
        return;
      }
    }
    if (isInvalidForm) return;

    if (numEduSelectedPackage > 0 && checkedEducationNames?.length == 0) {
      toast.error("Please select at least one education", toastOptions);
      setLoading(false);
      return;
    }

    if (numEmpSelectedPackage > 0 && checkedEmploymentNames?.length == 0) {
      toast.error("Please select at least one employment", toastOptions);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/data`,
        {
          backGroundDetails,
          finalBodyEduEmp,
        }
      );
      finalStatus = response.data.Status;
      ErrorResponse = response.data.Error;
      toastMethod();
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  //Method to handle the changes madde in the form in UI when package is selected
  function handleValueChangeInForm(event) {
    try {
      setbackGroundDetails((prevDetails) => ({
        ...prevDetails,
        [event.target.id]: event.target.value,
      }));
    } catch (error) {
      console.log("Error handling value changes in Form:", error);
    }
  }
  //Method to handle the change made in the form in UI when Service is selected
  function handleServiceValueChange(event) {
    try {
      setbackGroundDetails((prevDetails) => ({
        ...prevDetails,
        Type: event.target.value,
      }));
      setbackGroundDetails((prevDetails) => ({
        ...prevDetails,
        [event.target.name]: event.target.value,
      }));
    } catch (error) {
      console.log("Error handling service value change:", error);
    }
  }

  function handleStateChangeBgCheck(event) {
    try {
      if (event.target.value === "stateNone") {
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          State: "",
        }));
      } else {
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          State: event.target.value,
        }));
      }
    } catch (error) {
      console.log("Error during handling State Change :", error);
    }
  }

  function handleDlStateChange(event) {
    try {
      if (event.target.value === "dlStateNone") {
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          DLState: "",
        }));
      } else {
        setbackGroundDetails((prevDetails) => ({
          ...prevDetails,
          DLState: event.target.value,
        }));
      }
    } catch (error) {
      console.log("Error while handling DL state change: ", error);
    }
  }
  //Services Array from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/services_array`
        );
        const data = await response.json();
        setService(data);
      } catch (error) {
        console.error("Error fetching data Service Array:", error);
      }
    };
    fetchData();
  }, []);

  // Array containing PackageName and ID From backend
  useEffect(() => {
    const fetchPackageData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/packageNameId`
        );
        const data = await response.json();
        setPackages(data);
      } catch (error) {
        console.error("Error fetching data package:", error);
      }
    };

    fetchPackageData();
  }, []);

  // Education

  // When the user clicks the button, open the modal
  function handleModal(event) {
    try {
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
    } catch (error) {
      console.log(
        "Error in handling opening of Modal for  New Education: ",
        error
      );
    }
  }

  function handleCrossClickModal(event) {
    try {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";

      const eduObj = {
        dateAttendedEnd: "",
        dateAttendedStart: "",
        degreeAwarded: "",
        degreeDate: "",
        educationId: "",
        grade: "",
        institutionAttended: "",
        major: "",
        other: "",
        country: "",
        region: "",
        municipality: "",
        postalCode: "",
      };

      var newMapEducationAddon = new Map();
      newMapEducationAddon.set("0", eduObj);
      seteduDetailsForEducationCreation(newMapEducationAddon);
      var currentSelectedEdu = document.getElementsByClassName("0fields");
      for (let i = 0; i < currentSelectedEdu.length; i++) {
        currentSelectedEdu[i].value = "";
      }
    } catch (error) {
      console.log("Error in handling cross of modal: ", error);
    }
  }

  useEffect(() => {}, [eduDetailsForEducationCreation]);

  // When the user clicks on <span> (x), close the modal
  function handleModalClose(event) {
    try {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";

      const eduObj = {
        dateAttendedEnd: "",
        dateAttendedStart: "",
        degreeAwarded: "",
        degreeDate: "",
        educationId: "",
        grade: "",
        institutionAttended: "",
        major: "",
        other: "",
        country: "",
        region: "",
        municipality: "",
        postalCode: "",
      };

      var newMapEducationAddon = new Map();
      newMapEducationAddon.set("0", eduObj);
      seteduDetailsForEducationCreation(newMapEducationAddon);
      var currentSelectedEdu = document.getElementsByClassName("0fields");
      for (let i = 0; i < currentSelectedEdu.length; i++) {
        currentSelectedEdu[i].value = "";
      }
    } catch (error) {
      console.log("Error in Modal for new Education :", error);
    }
  }

  // Employment
  // When the user clicks the button, open the modal
  function handleEmploymentModal(event) {
    try {
      var modal = document.getElementById("employmentModal");
      modal.style.display = "block";
    } catch (error) {
      console.log(
        "Error in handling opening of Modal for  New Employment: ",
        error
      );
    }
  }

  function handleCrossClickEmploymentModal(event) {
    try {
      var modal = document.getElementById("employmentModal");
      modal.style.display = "none";

      const empObj = {
        startDate: "",
        endDate: "",
        employer: "",
        street1: "",
        street2: "",
        address: {
          country: "",
          region: "",
          municipality: "",
          postalCode: "",
          combined: "",
        },
        countryCode: 0,
        phoneNumber: "",
        phoneNumberFormat: "",
        phoneNumberCountryCallingCode: 0,
        position: "",
        pay: "",
        duties: "",
        supervisor: "",
        reasonForLeaving: "",
        supervisorFirstName: "",
        supervisorLastName: "",
        supervisorTitle: "",
        supervisorEmail: "",
        isFullTime: false,
        isPartTime: false,
        isTemporaryJob: false,
        isOkayToContact: false,
        includeInBackgroundChecks: false,
        bypassAddressValidationService: false,
      };

      var newMapEmploymentAddon = new Map();
      newMapEmploymentAddon.set("0", empObj);
      setDetailsForEmploymentCreation(newMapEmploymentAddon);
      var currentSelectedEmployment =
        document.getElementsByClassName("0fields");
      for (let i = 0; i < currentSelectedEmployment.length; i++) {
        currentSelectedEmployment[i].value = "";
      }
    } catch (error) {
      console.log("Error in handling cross of modal: ", error);
    }
  }

  useEffect(() => {}, [detailsForEmploymentCreation]);

  // When the user clicks on <span> (x), close the modal
  function handleEmploymentModalClose(event) {
    try {
      var modal = document.getElementById("employmentModal");
      modal.style.display = "none";

      const empObj = {
        startDate: "",
        endDate: "",
        employer: "",
        street1: "",
        street2: "",
        address: {
          country: "",
          region: "",
          municipality: "",
          postalCode: "",
          combined: "",
        },
        countryCode: 0,
        phoneNumber: "",
        phoneNumberFormat: "",
        phoneNumberCountryCallingCode: 0,
        position: "",
        pay: "",
        duties: "",
        supervisor: "",
        reasonForLeaving: "",
        supervisorFirstName: "",
        supervisorLastName: "",
        supervisorTitle: "",
        supervisorEmail: "",
        isFullTime: false,
        isPartTime: false,
        isTemporaryJob: false,
        isOkayToContact: false,
        includeInBackgroundChecks: false,
        bypassAddressValidationService: false,
      };

      var newMapEmploymentAddon = new Map();
      newMapEmploymentAddon.set("0", empObj);
      setDetailsForEmploymentCreation(newMapEmploymentAddon);
      var currentSelectedEmployment =
        document.getElementsByClassName("0fields");
      for (let i = 0; i < currentSelectedEmployment.length; i++) {
        currentSelectedEmployment[i].value = "";
      }
    } catch (error) {
      console.log("Error in Modal for new Employment :", error);
    }
  }

  return (
    <div className={styles.App}>
      <div>{loading ? <Spinner /> : <div></div>}</div>

      <div className={styles.indentation}>
        <div>
          <label forhtml="service-package">SELECT SERVICE(S)*</label>
          <div className={styles.box}>
            <div>
              <input
                type="checkbox"
                id={`service-package`}
                name="service"
                value="Package"
                onChange={handleServiceChange}
              />
              <label htmlFor={`service-package`}>Package</label>
            </div>
            {services_array.map((service, index) => (
              <div>
                <input
                  type="checkbox"
                  id={service.pname}
                  name="service"
                  className="ServicesClass"
                  // value={service.pname}
                  onChange={handleServiceChange}
                  disabled={isPackageSelected}
                  onClick={handleServiceSelections}
                />
                <label htmlFor={`service-${index}`}>{service.pname}</label>
              </div>
            ))}
          </div>
        </div>
        <div className={classNames(styles.gridContainer, "stateOptions")}>
          <label forhtml="stateDropdown">Select State:</label>
          <select
            name="States"
            disabled={isPackageSelected}
            onChange={handleStateChange}
            className={styles.SelectBox}
            id="stateDropdown"
          >
            <option value="" selected>
              -- None --
            </option>
            {statesArray.map((state, index) => (
              <option value={state.Name} className={styles.states}>
                {state.Name}
              </option>
            ))}
          </select>
        </div>
        <br></br>
        <br></br>
        <h4 className={styles.addArea}>Click to add</h4>
        <div className={styles.AreaBlock} id={styles.addArea}>
          <div id="areasForSelection">
            {(() => {
              if (AreaArray) {
                return AreaArray.map((area, index) => (
                  <div key={index} className={styles.selectArea}>
                    <div onClick={handleAreaClick}>{area.Area}</div>
                  </div>
                ));
              }
            })()}
          </div>
        </div>
        <h4 className={styles.removeArea}>Click To Remove</h4>
        <div className={styles.AreaBlock}>
          {(() => {
            if (newSet) {
              return Array.from(newSet).map((area, index) => (
                <div
                  disabled
                  key={index}
                  onClick={handleRemoveArea}
                  className={styles.selectArea}
                >
                  {area}
                </div>
              ));
            }
          })()}
        </div>
      </div>
      <div>
        <h4 className={styles.heading}>Select package:</h4>

        <select
          name="Package"
          className="packagesClass"
          disabled={isCheckboxSelected1}
          onChange={handlePackageChange}
          id="selectedPackage"
        >
          <option value="packageNone" selected>
            -- None --
          </option>
          {package_array.map((pkg, index) => (
            <option
              value={pkg.name}
              className={styles.states}
              id={pkg.Id}
              onClick={handleServiceSelections}
            >
              {pkg.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.dynamicForm}>
        <form onSubmit={handleSubmit} id="FORM1">
          <div className={styles.property}>
            {(() => {
              if (relatedFieldsArray && isPackageSelected === true) {
                return relatedFieldsArray
                  .filter((field, idx) => {
                    if (!numEmpSelectedPackage) return true;
                    else if (
                      numEmpSelectedPackage > 0 &&
                      ![
                        "CompName",
                        "CompAddress_1",
                        "CompCity_1",
                        "CompState_1",
                        "CompZip_1",
                        "TempAgency_1",
                        "CompLeave_1",
                        "HireDate_1",
                        "EndDate_1",
                        "CompTitle_1",
                        "CompSalary_1",
                        "CompContact_1",
                        "CompContactPhone_1",
                        "CompComments_1",
                        "SearchState",
                      ].includes(field.PropertyName)
                    )
                      return true;
                    else return false;
                  })
                  .map((element, index) => (
                    <div key={index} className={styles.formElements}>
                      {(() => {
                        if (element.PropertyName === "SSN1") {
                          return (
                            <label
                              htmlFor={element.ApiName}
                              size="20"
                              className={styles.fieldLabel}
                            >
                              SSN
                              {element.Required ? "*" : ""}
                            </label>
                          );
                        } else if (element.PropertyName === "DOBDAY") {
                          return (
                            <label
                              htmlFor={element.ApiName}
                              size="20"
                              className={styles.fieldLabel}
                            >
                              DOB
                              {element.Required ? "*" : ""}
                            </label>
                          );
                        } else if (
                          !element.PropertyName.includes("SSN") &&
                          !element.PropertyName.includes("CompName") &&
                          !element.PropertyName.includes("CompCity_1") &&
                          !element.PropertyName.includes("CompState_1") &&
                          element.Required === true
                        ) {
                          return (
                            <label
                              htmlFor={element.ApiName}
                              size="20"
                              className={styles.fieldLabel}
                            >
                              {element.ApiName}
                              {element.Required ? "*" : ""}
                            </label>
                          );
                        } else if (
                          element.PropertyName.includes("AdditionalInfo")
                        ) {
                          return (
                            <label
                              htmlFor={element.ApiName}
                              size="20"
                              className={styles.fieldLabel}
                            >
                              Additional Info
                            </label>
                          );
                        } else {
                          return (
                            <label
                              htmlFor={element.ApiName}
                              size="20"
                              className={styles.fieldLabel}
                            >
                              {element.ApiName}
                            </label>
                          );
                        }
                      })()}
                      {(() => {
                        if (element.PropertyName.includes("SSN1")) {
                          return (
                            <nobr>
                              <input
                                type="text"
                                className={classNames(`packageForminputs`, {
                                  "required-field": element.Required,
                                })}
                                // placeholder="SSN1"
                                maxLength="3"
                                onChange={handleValueChangeInForm}
                                id="SSN1"
                                name="SSN1"
                                style={{ width: "40px" }}
                                {...(element.Required
                                  ? { required: true }
                                  : {})}
                              />

                              <input
                                type="text"
                                className={classNames(`packageForminputs`, {
                                  "required-field": element.Required,
                                })}
                                // placeholder="SSN2"
                                maxLength="2"
                                onChange={handleValueChangeInForm}
                                // value={element.value}
                                style={{ width: "40px" }}
                                id="SSN2"
                                name="SSN2"
                                {...(element.Required
                                  ? { required: true }
                                  : {})}
                              />

                              <input
                                type="text"
                                className={classNames(`packageForminputs`, {
                                  "required-field": element.Required,
                                })}
                                // placeholder="SSN3"
                                maxLength="4"
                                onChange={handleValueChangeInForm}
                                // value={element.value}
                                style={{ width: "40px" }}
                                id="SSN3"
                                name="SSN3"
                                {...(element.Required
                                  ? { required: true }
                                  : {})}
                              />
                            </nobr>
                          );
                        } else if (element.PropertyName.includes("DOBDAY")) {
                          return (
                            <nobr>
                              <DatePicker
                                selected={selectedDob}
                                onChange={handleDobChange}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="MM/DD/YYYY"
                                id="DOB"
                                name="DOB"
                                className={classNames(`packageForminputs`, {
                                  "required-field": element.Required,
                                })}
                                style={{ width: "120px" }}
                                {...(element.Required
                                  ? { required: true }
                                  : {})}
                              />
                            </nobr>
                          );
                        } else if (element.ApiName === "State") {
                          return (
                            <select
                              name="States"
                              onChange={handleStateChangeBgCheck}
                              className={classNames(`packageForminputs`, {
                                "required-field": element.Required,
                              })}
                              {...(element.Required ? { required: true } : {})}
                            >
                              <option value="stateNone">-- None --</option>
                              {statesArray.map((state, index) => (
                                <option
                                  selected={state.Name === currentStatevalue}
                                >
                                  {state.Name}{" "}
                                </option>
                              ))}
                            </select>
                          );
                        } else if (element.PropertyName === "DLState") {
                          return (
                            <select
                              name="States"
                              onChange={handleDlStateChange}
                              className={classNames(`packageForminputs`, {
                                "required-field": element.Required,
                              })}
                              {...(element.Required ? { required: true } : {})}
                            >
                              <option value="dlStateNone">-- None --</option>
                              {dlStates.map((state, index) => (
                                <option
                                  selected={
                                    state.Name === backGroundDetails.DLState
                                  }
                                >
                                  {state.Name}{" "}
                                </option>
                              ))}
                            </select>
                          );
                        } else if (
                          element.DataType === "checkbox" &&
                          element.Required === false
                        ) {
                          return (
                            <input
                              size="20"
                              className={classNames(`packageForminputs`, {
                                "required-field": element.Required,
                              })}
                              // value={element.value}
                              type={element.DataType}
                              id={element.PropertyName}
                              name={element.ApiName}
                              onClick={handleCheckbox}
                              {...(element.Required ? { required: true } : {})}
                            />
                          );
                        } else if (
                          element.DataType === "checkbox" &&
                          element.Required === false
                        ) {
                          return (
                            <input
                              size="20"
                              className={classNames(`packageForminputs`, {
                                "required-field": element.Required,
                              })}
                              // value={element.value}
                              type={element.DataType}
                              id={element.PropertyName}
                              name={element.ApiName}
                              onClick={handleCheckbox}
                              {...(element.Required ? { required: true } : {})}
                            />
                          );
                        } else if (
                          element.Required === false &&
                          !element.PropertyName.includes("SSN")
                        ) {
                          return (
                            <input
                              size="20"
                              className={classNames(`packageForminputs`, {
                                "required-field": element.Required,
                              })}
                              // value={element.value}N
                              type={element.DataType}
                              id={element.PropertyName}
                              name={element.ApiName}
                              onChange={handleValueChangeInForm}
                              {...(element.Required ? { required: true } : {})}
                            />
                          );
                        } else if (
                          element.PropertyName.includes("CompName") ||
                          element.PropertyName.includes("CompCity_1") ||
                          element.PropertyName.includes("CompState_1")
                        ) {
                          return (
                            <input
                              size="20"
                              className={classNames(`packageForminputs`, {
                                "required-field": element.Required,
                              })}
                              // value={element.value}
                              type={element.DataType}
                              id={element.PropertyName}
                              name={element.ApiName}
                              onChange={handleValueChangeInForm}
                              {...(element.Required ? { required: true } : {})}
                            />
                          );
                        } else if (
                          !element.PropertyName.includes("SSN") &&
                          !element.PropertyName.includes("CompName") &&
                          !element.PropertyName.includes("CompCity_1") &&
                          !element.PropertyName.includes("CompState_1")
                        ) {
                          return (
                            <input
                              size="20"
                              className={classNames(`packageForminputs`, {
                                "required-field": element.Required,
                              })}
                              // value={element.value}
                              type={element.DataType}
                              id={element.PropertyName}
                              name={element.ApiName}
                              onChange={handleValueChangeInForm}
                              {...(element.Required ? { required: true } : {})}
                            />
                          );
                        }
                      })()}
                    </div>
                  ));
              } else if (
                relatedFieldsServiceArray &&
                isPackageSelected === false
              ) {
                return relatedFieldsServiceArray.map((service, index) => (
                  <div key={index} className={styles.formElements}>
                    <label
                      htmlFor={service.value}
                      size="20"
                      className={styles.fieldLabel}
                    >
                      {service.ApiName}
                      {service.Required ? "*" : ""}
                    </label>

                    {(() => {
                      if (service.PropertyName.includes("SSN1")) {
                        return (
                          <nobr>
                            <input
                              type="text"
                              className={classNames(`packageForminputs`, {
                                "required-field": service.Required,
                              })}
                              // placeholder="SSN1"
                              maxLength="3"
                              onChange={handleValueChangeInForm}
                              id="SSN1"
                              name="SSN1"
                              style={{ width: "40px" }}
                              {...(service.Required ? { required: true } : {})}
                            />

                            <input
                              type="text"
                              className={classNames(`packageForminputs`, {
                                "required-field": service.Required,
                              })}
                              // placeholder="SSN2"
                              maxLength="2"
                              onChange={handleValueChangeInForm}
                              // value={element.value}
                              style={{ width: "40px" }}
                              id="SSN2"
                              name="SSN2"
                              {...(service.Required ? { required: true } : {})}
                            />

                            <input
                              type="text"
                              className={classNames(`packageForminputs`, {
                                "required-field": service.Required,
                              })}
                              // placeholder="SSN3"
                              maxLength="4"
                              onChange={handleValueChangeInForm}
                              // value={element.value}
                              style={{ width: "40px" }}
                              id="SSN3"
                              name="SSN3"
                              {...(service.Required ? { required: true } : {})}
                            />
                          </nobr>
                        );
                      } else if (service.PropertyName === "DOBDAY") {
                        return (
                          <nobr>
                            <DatePicker
                              selected={selectedDob}
                              onChange={handleDobChange}
                              dateFormat="MM/dd/yyyy"
                              placeholderText="MM/DD/YYYY"
                              id="DOB"
                              name="DOB"
                              className={classNames(`packageForminputs`, {
                                "required-field": service.Required,
                              })}
                              style={{ width: "120px" }}
                              {...(service.Required ? { required: true } : {})}
                            />
                          </nobr>
                        );
                      } else if (service.PropertyName === "YearsSearch") {
                        return (
                          <input
                            size="20"
                            className={classNames(`serviceinputs`, {
                              "required-field": service.Required,
                            })}
                            name={service.PropertyName}
                            type={service.DataType}
                            id={service.value}
                            onChange={handleServiceValueChange}
                            min="7"
                            max="30"
                            {...(service.Required ? { required: true } : {})}
                          />
                        );
                      } else if (service.PropertyName === "State") {
                        return (
                          <select
                            name="States"
                            onChange={handleStateChangeBgCheck}
                            className={classNames(`serviceinputs`, {
                              "required-field": service.Required,
                            })}
                            {...(service.Required ? { required: true } : {})}
                          >
                            <option value="stateNone">-- None --</option>
                            {statesArray.map((state, index) => (
                              <option
                                selected={state.Name === currentStatevalue}
                              >
                                {state.Name}{" "}
                              </option>
                            ))}
                          </select>
                        );
                      } else if (service.PropertyName === "DLState") {
                        return (
                          <select
                            name="States"
                            onChange={handleDlStateChange}
                            className={classNames(`serviceinputs`, {
                              "required-field": service.Required,
                            })}
                            {...(service.Required ? { required: true } : {})}
                          >
                            <option value="dlStateNone">-- None --</option>
                            {dlStates.map((state, index) => (
                              <option
                                selected={
                                  state.Name === backGroundDetails.DLState
                                }
                              >
                                {state.Name}{" "}
                              </option>
                            ))}
                          </select>
                        );
                      } else if (service.PropertyName === "MiddleInitial") {
                        return (
                          <input
                            size="20"
                            className={classNames(`serviceinputs`, {
                              "required-field": service.Required,
                            })}
                            name={service.PropertyName}
                            type={service.DataType}
                            id={service.value}
                            onChange={handleServiceValueChange}
                            {...(service.Required ? { required: true } : {})}
                          />
                        );
                      } else {
                        return (
                          <input
                            size="20"
                            className={classNames(`serviceinputs`, {
                              "required-field": service.Required,
                            })}
                            name={service.PropertyName}
                            type={service.DataType}
                            id={service.value}
                            onChange={handleServiceValueChange}
                            {...(service.Required ? { required: true } : {})}
                          />
                        );
                      }
                    })()}
                  </div>
                ));
              }
            })()}
          </div>
          <div className={classNames(styles.EduEmpBox, styles.dynamicForm)}>
            <div className={styles.eduEmpBoxinner}>
              {numEduSelectedPackage > 0 && (
                <>
                  <strong>Education</strong>
                  <h5>You can select upto {numEduSelectedPackage} Education</h5>
                  {educationIdVsEducationDetails &&
                    Array.from(educationIdVsEducationDetails.entries())
                      .filter(
                        ([key, value]) =>
                          value.institutionAttended &&
                          value.institutionAttended.trim() !== ""
                      )
                      .map(([key, value]) => (
                        <div key={key}>
                          <input
                            type="checkbox"
                            name={key}
                            onClick={handleEducation}
                            className="eduCheckbox"
                            disabled={disableEducation}
                          />
                          <label htmlFor={key}>
                            {value.institutionAttended}
                          </label>

                          <div
                            id={key}
                            className={classNames(
                              styles.w3container,
                              styles.w3hide
                            )}
                            data-elem-id={`EDU-${key}`}
                          >
                            <form
                              onSubmit={handleSubmit}
                              className="innerEduForm"
                            >
                              <div className={styles.property}>
                                {educationFieldsArray.map(
                                  (education, index) => (
                                    <div key={index}>
                                      <label
                                        htmlFor={key}
                                        size="20"
                                        className={styles.fieldLabel}
                                      >
                                        {education.ApiName}
                                        {education.Required ? "*" : ""}
                                      </label>
                                      {(() => {
                                        if (education.ApiName === "Country") {
                                          return (
                                            <select
                                              name={education.PropertyName}
                                              id={key}
                                              className={classNames(
                                                `eduData`,
                                                `${key}fields`,
                                                {
                                                  "required-field":
                                                    education.Required,
                                                }
                                              )}
                                              onChange={
                                                handleEducationCountryChange
                                              }
                                              value={
                                                educationEntries[key]
                                                  ?.selectedCountry
                                              }
                                              {...(education.Required
                                                ? { required: true }
                                                : {})}
                                            >
                                              <option value="">
                                                {" "}
                                                -- Select --{" "}
                                              </option>
                                              {countryList.map(
                                                (country, index) => (
                                                  <option
                                                    key={index}
                                                    value={country}
                                                  >
                                                    {country}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          );
                                        } else if (
                                          education.ApiName === "State"
                                        ) {
                                          return (
                                            <select
                                              name={education.PropertyName}
                                              id={key}
                                              className={classNames(
                                                `eduData`,
                                                `${key}fields`,
                                                {
                                                  "required-field":
                                                    education.Required,
                                                }
                                              )}
                                              value={
                                                educationEntries[key]
                                                  ?.selectedState
                                              }
                                              onChange={
                                                handleEducationStateChange
                                              }
                                              {...(education.Required
                                                ? { required: true }
                                                : {})}
                                            >
                                              <option value="">
                                                {" "}
                                                -- Select --{" "}
                                              </option>
                                              {educationEntries[
                                                key
                                              ]?.stateCodesBasedOnCountry?.map(
                                                (stateCode, stateIndex) => (
                                                  <option
                                                    key={stateIndex}
                                                    value={stateCode}
                                                  >
                                                    {stateCode}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          );
                                        } else {
                                          return (
                                            <input
                                              size="20"
                                              name={education.PropertyName}
                                              type={education.DataType}
                                              id={key}
                                              className={classNames(
                                                `eduData`,
                                                `${key}fields`,
                                                {
                                                  "required-field":
                                                    education.Required,
                                                }
                                              )}
                                              onChange={handleEducationChange}
                                              {...(education.Required
                                                ? { required: true }
                                                : {})}
                                            />
                                          );
                                        }
                                      })()}
                                    </div>
                                  )
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      ))}
                  {Array.from(eduDetailsForEducationCreation.entries()).map(
                    ([key, value]) => (
                      <div key={key}>
                        <button type="button" id="myBtn" onClick={handleModal}>
                          Add Education
                        </button>
                      </div>
                    )
                  )}
                  {/* To check if it is required or now */}
                  {/* <div
                    className={classNames(styles.w3container, styles.w3hide)}
                  >
                    <form onSubmit={handleSubmit} className="innerEduForm">
                      <div className={styles.property}>
                        {(() => {
                          if (educationCreationFieldsArray.length !== 0) {
                            return educationCreationFieldsArray.map(
                              (education, index) => (
                                <div key={index}>
                                  <label
                                    size="20"
                                    className={styles.fieldLabel}
                                  >
                                    {education.ApiName}
                                    {education.Required ? "*" : ""}
                                  </label>
                                  {(() => {
                                    if (education.ApiName === "Country") {
                                      return (
                                        <select
                                          name={education.ApiName}
                                          value={education.value}
                                          onChange={handleEducationChange}
                                          {...(education.Required
                                            ? { required: true }
                                            : {})}
                                        >
                                          <option value="CountryNone">
                                            -- None --
                                          </option>
                                          {countryList.map((country, index) => (
                                            <option key={index} value={country}>
                                              {country}
                                            </option>
                                          ))}
                                        </select>
                                      );
                                    } else {
                                      return (
                                        <input
                                          id={index}
                                          className={classNames({
                                            "required-field": education.Required,
                                          })}
                                          size="20"
                                          name={education.PropertyName}
                                          type={education.DataType}
                                          {...(education.Required
                                            ? { required: true }
                                            : {})}
                                        />
                                      );
                                    }
                                  })()}
                                </div>
                              )
                            );
                          } else {
                            <h1>Error</h1>;
                          }
                          return;
                        })()}
                      </div>
                    </form>
                  </div> */}
                </>
              )}
            </div>
            <div className={styles.eduEmpBoxinner}>
              {numEmpSelectedPackage > 0 && (
                <>
                  <strong> Employment</strong>
                  <h5>
                    You can select upto {numEmpSelectedPackage} Employment
                  </h5>
                  {(() => {
                    if (WorkHistoryIdVsWorkHistoryDetails) {
                      return Array.from(
                        WorkHistoryIdVsWorkHistoryDetails.entries()
                      )
                        .filter(
                          ([key, value]) =>
                            value.employer && value.employer.trim() !== ""
                        )
                        .map(([key, value]) => (
                          <div key={key}>
                            <input
                              type="checkbox"
                              name={key}
                              className="employmentCheckbox"
                              disabled={disableEmployment}
                              onClick={handleWorkHistory}
                            ></input>
                            <label htmlFor={key}>{value.employer}</label>
                            <div
                              id={key}
                              className={classNames(
                                styles.w3container,
                                styles.w3hide
                              )}
                              data-elem-id={`EMP-${key}`}
                            >
                              <form
                                onSubmit={handleSubmit}
                                className="innerEmpForm"
                              >
                                <div className={styles.property}>
                                  {(() => {
                                    return employmentFieldArray.map(
                                      (employment, index) => (
                                        <div key={index}>
                                          <label
                                            htmlFor={key}
                                            size="20"
                                            className={styles.fieldLabel}
                                          >
                                            {employment.ApiName}
                                            {employment.Required ? "*" : ""}
                                          </label>
                                          {(() => {
                                            if (
                                              employment.ApiName ===
                                              "Comp Country"
                                            ) {
                                              return (
                                                <select
                                                  name={employment.PropertyName}
                                                  id={key}
                                                  className={classNames(
                                                    `empData`,
                                                    `${key}fields`,
                                                    {
                                                      "required-field":
                                                        employment.Required,
                                                    }
                                                  )}
                                                  onChange={
                                                    handleEmploymentCountryChange
                                                  }
                                                  value={
                                                    employmentEntries[key]
                                                      ?.selectedCountry
                                                  }
                                                  {...(employment.Required
                                                    ? { required: true }
                                                    : {})}
                                                >
                                                  <option value="">
                                                    {" "}
                                                    -- Select --{" "}
                                                  </option>
                                                  {countryList.map(
                                                    (country, index) => (
                                                      <option
                                                        key={index}
                                                        value={country}
                                                      >
                                                        {country}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              );
                                            } else if (
                                              employment.ApiName ===
                                              "Comp State"
                                            ) {
                                              return (
                                                <select
                                                  name={employment.PropertyName}
                                                  id={key}
                                                  className={classNames(
                                                    `empData`,
                                                    `${key}fields`,
                                                    {
                                                      "required-field":
                                                        employment.Required,
                                                    }
                                                  )}
                                                  value={
                                                    employmentEntries[key]
                                                      ?.selectedState
                                                  }
                                                  onChange={
                                                    handleEmploymentStateChange
                                                  }
                                                  {...(employment.Required
                                                    ? { required: true }
                                                    : {})}
                                                >
                                                  <option value="">
                                                    {" "}
                                                    -- Select --{" "}
                                                  </option>
                                                  {employmentEntries[
                                                    key
                                                  ]?.stateCodesBasedOnCountry?.map(
                                                    (stateCode, stateIndex) => (
                                                      <option
                                                        key={stateIndex}
                                                        value={stateCode}
                                                      >
                                                        {stateCode}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              );
                                            } else if (
                                              employment.ApiName ===
                                              "Phone Format"
                                            ) {
                                              return (
                                                <select
                                                  name={employment.PropertyName}
                                                  id={key}
                                                  className={classNames(
                                                    `empData`,
                                                    `${key}fields`,
                                                    {
                                                      "required-field":
                                                        employment.Required,
                                                    }
                                                  )}
                                                  onChange={handleWorkChange}
                                                  {...(employment.Required
                                                    ? { required: true }
                                                    : {})}
                                                >
                                                  <option value="">
                                                    {" "}
                                                    -- Select --{" "}
                                                  </option>
                                                  {phoneNumberFormatList.map(
                                                    (format, index) => (
                                                      <option
                                                        key={index}
                                                        value={format}
                                                      >
                                                        {format}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              );
                                            } else {
                                              return (
                                                <input
                                                  size="20"
                                                  name={employment.PropertyName}
                                                  type={employment.DataType}
                                                  id={key}
                                                  className={classNames(
                                                    `empData`,
                                                    `${key}fields`,
                                                    {
                                                      "required-field":
                                                        employment.Required,
                                                    }
                                                  )}
                                                  onChange={handleWorkChange}
                                                  {...(employment.Required
                                                    ? { required: true }
                                                    : {})}
                                                />
                                              );
                                            }
                                          })()}
                                        </div>
                                      )
                                    );
                                  })()}
                                </div>
                              </form>
                            </div>
                          </div>
                        ));
                    }
                  })()}

                  {Array.from(detailsForEmploymentCreation.entries()).map(
                    ([key, value]) => (
                      <div key={key}>
                        <button
                          type="button"
                          id="employmentAddBtn"
                          onClick={handleEmploymentModal}
                        >
                          Add Employment
                        </button>
                      </div>
                    )
                  )}

                  {/* To check if it is required or now */}
                  {/* <div
                    className={classNames(styles.w3container, styles.w3hide)}
                  >
                    <form onSubmit={handleSubmit} className="innerEmpForm">
                      <div className={styles.property}>
                        {(() => {
                          if (employmentCreationFieldsArray.length !== 0) {
                            return employmentCreationFieldsArray.map(
                              (employment, index) => (
                                <div key={index}>
                                  <label
                                    size="20"
                                    className={styles.fieldLabel}
                                  >
                                    {employment.ApiName}
                                    {employment.Required ? "*" : ""}
                                  </label>
                                  <input
                                    id={index}
                                    className={classNames({
                                      "required-field": employment.Required,
                                    })}
                                    size="20"
                                    name={employment.PropertyName}
                                    type={employment.DataType}
                                    {...(employment.Required
                                      ? { required: true }
                                      : {})}
                                  />
                                </div>
                              )
                            );
                          } else {
                            <h1>Error</h1>;
                          }
                          return;
                        })()}
                      </div>
                    </form>
                  </div> */}
                </>
              )}
            </div>
          </div>{" "}
          {(() => {
            if (submitButtonCheckbox === true) {
              return (
                <input
                  type="submit"
                  value="Submit"
                  className={styles.submit}
                  form="FORM1"
                ></input>
              );
            } else if (submitButtonCheckbox === false) {
              return (
                <input
                  type="submit"
                  value="Submit"
                  className={styles.submit}
                  form="FORM1"
                  disabled
                ></input>
              );
            }
          })()}
        </form>
      </div>

      <ToastContainer />
      {Array.from(eduDetailsForEducationCreation.entries()).map(
        ([key, value]) => (
          <div key={key}>
            <div
              id="myModal"
              className={styles.modal}
              style={{ display: "none" }}
            >
              <div className={styles.modalcontent}>
                <form onSubmit={handleEduCreation} id="FORM2">
                  <div>
                    <span
                      className={styles.close}
                      onClick={handleCrossClickModal}
                    >
                      &times;
                    </span>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Create New Education
                    </p>
                  </div>
                  <hr></hr>
                  <div className={styles.property}>
                    {educationFieldsArray.map((education, index) => {
                      let fieldContent;

                      if (education.ApiName === "Country") {
                        fieldContent = (
                          <select
                            name={education.PropertyName}
                            id={key}
                            className={classNames(`eduData`, `${key}fields`, {
                              "required-field": education.Required,
                            })}
                            onChange={handleEducationCountryChange}
                            value={educationEntries[key]?.selectedCountry}
                            {...(education.Required ? { required: true } : {})}
                          >
                            <option value=""> -- Select -- </option>
                            {countryList.map((country, index) => (
                              <option key={index} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        );
                      } else if (education.ApiName === "State") {
                        fieldContent = (
                          <select
                            name={education.PropertyName}
                            id={key}
                            className={classNames(`eduData`, `${key}fields`, {
                              "required-field": education.Required,
                            })}
                            value={educationEntries[key]?.selectedState}
                            onChange={handleEducationStateChange}
                            {...(education.Required ? { required: true } : {})}
                          >
                            <option value=""> -- Select -- </option>
                            {educationEntries[
                              key
                            ]?.stateCodesBasedOnCountry?.map(
                              (stateCode, stateIndex) => (
                                <option key={stateIndex} value={stateCode}>
                                  {stateCode}
                                </option>
                              )
                            )}
                          </select>
                        );
                      } else {
                        fieldContent = (
                          <input
                            size="20"
                            name={education.PropertyName}
                            type={education.DataType}
                            id={key}
                            className={classNames(`eduData`, `${key}fields`, {
                              "required-field": education.Required,
                            })}
                            onChange={handleEducationChange}
                            {...(education.Required ? { required: true } : {})}
                          />
                        );
                      }

                      return (
                        <div key={index}>
                          <label
                            htmlFor={key}
                            size="20"
                            className={styles.fieldLabel}
                          >
                            {education.ApiName}
                            {education.Required ? "*" : ""}
                          </label>

                          {fieldContent}
                        </div>
                      );
                    })}
                  </div>
                  <hr></hr>
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="submit"
                      value="Create Education"
                      form="FORM2"
                      className={styles.createEducation}
                      // onClick={handleEduCreation}
                    ></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )
      )}

      {Array.from(detailsForEmploymentCreation.entries()).map(
        ([key, value]) => (
          <div key={key}>
            <div
              id="employmentModal"
              className={styles.modal}
              style={{ display: "none" }}
            >
              <div className={styles.modalcontent}>
                <form onSubmit={handleEmploymentCreation} id="FORM3">
                  <div>
                    <span
                      className={styles.close}
                      onClick={handleCrossClickEmploymentModal}
                    >
                      &times;
                    </span>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Create New Employment
                    </p>
                  </div>
                  <hr></hr>
                  <div className={styles.property}>
                    {employmentFieldArray.map((employment, index) => {
                      let fieldContent;

                      if (employment.ApiName === "Comp Country") {
                        fieldContent = (
                          <select
                            name={employment.PropertyName}
                            id={key}
                            className={classNames(`empData`, `${key}fields`, {
                              "required-field": employment.Required,
                            })}
                            onChange={handleEmploymentCountryChange}
                            value={employmentEntries[key]?.selectedCountry}
                            {...(employment.Required ? { required: true } : {})}
                          >
                            <option value=""> -- Select -- </option>
                            {countryList.map((country, index) => (
                              <option key={index} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        );
                      } else if (employment.ApiName === "Comp State") {
                        fieldContent = (
                          <select
                            name={employment.PropertyName}
                            id={key}
                            className={classNames(`empData`, `${key}fields`, {
                              "required-field": employment.Required,
                            })}
                            value={employmentEntries[key]?.selectedState}
                            onChange={handleEmploymentStateChange}
                            {...(employment.Required ? { required: true } : {})}
                          >
                            <option value=""> -- Select -- </option>
                            {employmentEntries[
                              key
                            ]?.stateCodesBasedOnCountry?.map(
                              (stateCode, stateIndex) => (
                                <option key={stateIndex} value={stateCode}>
                                  {stateCode}
                                </option>
                              )
                            )}
                          </select>
                        );
                      } else if (employment.ApiName === "Phone Format") {
                        fieldContent = (
                          <select
                            name={employment.PropertyName}
                            id={key}
                            className={classNames(`empData`, `${key}fields`, {
                              "required-field": employment.Required,
                            })}
                            onChange={handleWorkChange}
                            {...(employment.Required ? { required: true } : {})}
                          >
                            <option value=""> -- Select -- </option>
                            {phoneNumberFormatList.map((format, index) => (
                              <option key={index} value={format}>
                                {format}
                              </option>
                            ))}
                          </select>
                        );
                      } else {
                        fieldContent = (
                          <input
                            size="20"
                            name={employment.PropertyName}
                            type={employment.DataType}
                            id={key}
                            className={classNames(`empData`, `${key}fields`, {
                              "required-field": employment.Required,
                            })}
                            onChange={handleWorkChange}
                            {...(employment.Required ? { required: true } : {})}
                          />
                        );
                      }

                      return (
                        <div key={index}>
                          <label
                            htmlFor={key}
                            size="20"
                            className={styles.fieldLabel}
                          >
                            {employment.ApiName}
                            {employment.Required ? "*" : ""}
                          </label>

                          {fieldContent}
                        </div>
                      );
                    })}
                  </div>
                  <hr></hr>
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="submit"
                      value="Create Employment"
                      form="FORM3"
                      className={styles.createEmployment}
                    ></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )
      )}
    </div>
    
  );
}
export default App;
